import { useSelector, useDispatch } from "react-redux";

import {
    setShowMainPageView,
    setShowOrderView
} from "../../features/global/globalSlice";
import Layout3 from "../layouts/Layout3";


const OrderView = () => {

    const { 
        showOrderView
    } = 
    useSelector(
        state => state.global
    )

    const dispatch = useDispatch()

    const handleClose = () => {

        dispatch(setShowMainPageView(true))

        dispatch(setShowOrderView(false))

    }

    return (

        <>

            {
                showOrderView && (

                    <Layout3 
                        onClick={handleClose}
                        mainClass={'w-screen h-screen'}
                        headerText={'Customer Order'}
                        headerBackgroundColor={'bg-hot-pink'}
                        headerForgroundColor={'text-white'}
                        bodyForegroundColor={'text-white'}
                    >
                        <div className="
                            w-full
                            h-full
                            col-span-12
                            p-1"
                        >

                            <div className="
                                w-full
                                h-full
                                grid
                                grid-rows-12
                                gap-1"
                            >

                                <div className="
                                    w-full
                                    h-full
                                    row-start-1
                                    row-end-11"
                                >

                                    <div className="
                                        w-full
                                        h-full
                                        grid
                                        grid-cols-12
                                        gap-1"
                                    >

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-1
                                            col-end-3"
                                        >

                                            <div className="
                                                w-full
                                                h-full
                                                grid
                                                grid-rows-12
                                                gap-1"
                                            >

                                                <div className="
                                                    w-full
                                                    h-full
                                                    row-start-1
                                                    row-end-7
                                                    bg-black"
                                                >
                                                    
                                                    Top

                                                </div>

                                                <div className="
                                                    w-full
                                                    h-full
                                                    row-start-7
                                                    row-end-13
                                                    bg-black"
                                                >
                                                    
                                                    Bottom

                                                </div>

                                            </div>

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-3
                                            col-end-9
                                            bg-black"
                                        >

                                            Middle

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-9
                                            col-end-13
                                            bg-black"
                                        >

                                            Right

                                        </div>

                                    </div>

                                </div>

                                <div className="
                                    w-full
                                    h-full
                                    row-start-11
                                    row-end-13"
                                >

                                    <div className="
                                        w-full
                                        h-full
                                        grid
                                        grid-cols-12
                                        gap-1"
                                    >

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-1
                                            col-end-4
                                            bg-black"
                                        >
                                        
                                            1

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-4
                                            col-end-7
                                            bg-black"
                                        >
                                        
                                            2

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-7
                                            col-end-10
                                            bg-black"
                                        >
                                        
                                            3

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-10
                                            col-end-13
                                            bg-black"
                                        >
                                        
                                            4

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </Layout3>

                )
            }

        </>

    )
}

export default OrderView