import { useSelector, useDispatch } from "react-redux";
import { FaArrowLeft, FaBars } from 'react-icons/fa';
import SideBarOne from '../shared/SideBarOne';
import ProductSearchViewSideBarInfo from "./ProductSearchViewSideBarInfo";
import largeTimes from '../assets/large-times.svg'

import { 
    setShowSidebar,
    setShowMainPageView,
    setShowProductSearchView
} from "../../features/global/globalSlice";

import { 
    setShowProductSearchViewSideBarInfo
} from "../../features/products/productSlice";

const ProductSearchView = () => {

    const dispatch = useDispatch()

    const { 
        showSidebar,
        showProductSearchView
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showProductSearchViewSideBarInfo
    } = 
    useSelector(
        state => state.product
    )

    return (

        <div    
            onClick={ () => { 
                showSidebar && dispatch(setShowSidebar(!showSidebar)) 
                showProductSearchViewSideBarInfo && dispatch(setShowProductSearchViewSideBarInfo(!showProductSearchViewSideBarInfo))
            } } 
            className={`
                h-screen 
                grid 
                grid-rows-12 
                text-white
                ${
                    showProductSearchView 
                    ? "w-screen" 
                    : "hidden"
                }`
            }
        >
            
            <div className="
                w-full 
                row-start-1 
                row-end-2 
                grid 
                grid-cols-12 
                bg-hot-pink"
            >
            
                <div className="col-start-1 col-end-5 flex items-center pl-2">

                    <button 
                        onClick={ () => {
                            dispatch(setShowProductSearchView(false))
                            dispatch(setShowMainPageView(true))
                        } }
                        className="
                            flex
                            justify-center
                            items-center
                            md:h-14 
                            md:w-14 
                            1xl:h-14 
                            1xl:w-14 
                            2xl:h-20 
                            2xl:w-20
                            rounded-full
                            bg-white"
                    >
                        <FaArrowLeft className="arrow-left-fa text-hot-pink"/>
                    </button>

                </div>

                <div className="col-start-5 col-end-9">TOP MIDDLE</div>

                <div className="col-start-9 col-end-13 grid grid-cols-6">

                    <div className="w-full-h-full col-span-4"></div>

                    <div className="
                        w-full
                        h-full 
                        col-start-5 
                        col-end-6 
                        flex 
                        justify-center 
                        items-center"
                    >

                        <button 
                            onClick={() => { 
                                dispatch(setShowSidebar(!showSidebar));
                                dispatch(setShowProductSearchViewSideBarInfo(!showProductSearchViewSideBarInfo)) 
                            } }
                        >
                            { !showSidebar && <FaBars className="bars-large-fa" /> }
                        </button>

                    </div>

                    <div className="
                        w-full
                        h-full 
                        col-start-6 
                        col-end-7
                        flex 
                        justify-center 
                        items-center"
                    >
                        <button 
                            onClick={() => { 
                                dispatch(setShowProductSearchView(false))
                                dispatch(setShowMainPageView(true))
                            } }
                        >
                            <img
                                className="times-large-fa text-white"
                                alt="Close"
                                src={largeTimes}
                            />
                        </button>
                        

                    </div>

                </div>
            
            </div>

            <div className="w-full h-full row-start-2 row-end-13 grid grid-cols-12 bg-yellow-500">

                <div className="col-start-1 col-end-10 bg-yellow-500">

                    Product Search

                </div>

                <div className="col-start-10 col-end-13 bg-orange-500">

                    Sidebar

                </div>
                
            </div>

            <SideBarOne>
                <ProductSearchViewSideBarInfo />
            </SideBarOne>

        </div>

    )
}

export default ProductSearchView