import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import largeTimes from '../assets/large-times.svg'
import CircleButtonOne from "./CircleButtonOne";

import { 
    setShowMainPageView,
    setShowOrderView
} from "../../features/global/globalSlice";

import { 
    setShowCollapsibleTwo
} from "../../features/mainpage/mainPageSlice";

import { 
    setIsActiveArray,
    setIsActiveList
} from "../../features/scanned-item/scannedItemSlice";

const CollapsibleTwo = () => {

    const { 
        showCollapsibleTwo
    } = 
    useSelector(
        state => state.mainpage
    )

    const { 
        scannedItems,
        isActiveList
    } = 
    useSelector(
        state => state.scanneditem
    )

    const dispatch = useDispatch()

    let itemCount = 0
    
    isActiveList.map((item, index) => {
            
        return item !== "" && itemCount ++

    })

    const closeCollapsibleTwo = () => {

        dispatch(setShowCollapsibleTwo(false))

        dispatch(setIsActiveArray(new Array(scannedItems.length).fill(false))) 

        dispatch(setIsActiveList(new Array(scannedItems.length).fill("")))

    }

    const handleAddOrderClick = () => {

        dispatch(setShowOrderView(true))

        dispatch(setShowMainPageView(false))

    }

    const handleDiscountClick = () => {



    }

    const handleRefundClick = () => {



    }

    const handleRemoveItemsClick = () => {



    }

    return (

        <div 
            className={`
                absolute 
                h-full 
                top-0 
                left-0 
                z-50 
                bg-blue-400
                text-white
                md:rounded-tr-2xl
                md:rounded-br-2xl
                2xl:rounded-tr-3xl
                2xl:rounded-br-3xl
                ${showCollapsibleTwo ? "w-1/3 transition-[width] duration-500" : "hidden transition-[width] duration-500"} `}
        >
        
            <div className="
                w-full 
                h-full 
                grid 
                grid-rows-12"
            >

                {/* { 
                    isActiveList.map((item, index) => (
                
                    <p key={index}>{item}</p>

                    )) 
                } */}

                <div className="
                    w-full
                    h-full
                    row-start-1 
                    row-end-2"
                >
                    
                    <div className="
                        w-full
                        h-full
                        flex
                        justify-end
                        items-center
                        pr-2"
                    >

                        <button
                            onClick={closeCollapsibleTwo}
                        >
                            
                            <img
                                className="edit-product-times-large-fa text-white"
                                alt="Close"
                                src={largeTimes}
                            />

                        </button>

                    </div>
                    
                </div>

                <div className="
                    w-full
                    h-full
                    row-start-2
                    row-end-12
                    flex
                    flex-col
                    justify-around
                    items-center"
                >

                    <CircleButtonOne 
                        onClick={handleAddOrderClick}
                        cssText={'bg-white text-blue-400'} 
                        text={'Add to Order'} 
                    />

                    <CircleButtonOne 
                        onClick={handleDiscountClick}
                        cssText={'bg-white text-blue-400'} 
                        text={'Discount'} 
                    />

                    <CircleButtonOne 
                        onClick={handleRefundClick}
                        cssText={'bg-white text-blue-400'} 
                        text={'Refund'} 
                    />

                    <CircleButtonOne 
                        onClick={handleRemoveItemsClick}
                        cssText={'bg-white text-blue-400'} 
                        text={'Remove Items'} 
                    />

                </div>

                <div className="
                    w-full
                    h-full
                    row-start-12
                    row-end-13
                    flex
                    items-center
                    p-2
                    md:text-xl
                    2xl:text-3xl
                    text-center"
                >

                    {itemCount} {itemCount == 1 ? 'item' : 'items'} selected

                </div>

            </div>

        </div>

    )

}

export default CollapsibleTwo