const ButtonWithRoundedCornersAndText = ({
        onClick, 
        name,
        text, 
        classText, 
        selected,
        inactiveBackColor,
        activeBackColor,
        inactiveForeColor,
        activeForeColor,
        multiple
}) => {

    return (

        <button
            onClick={()=>onClick(name)}
            className={`
                w-full
                flex 
                justify-center
                items-center
                md:rounded-xl
                2xl:rounded-2xl
                p-2
                mb-2
                ${classText}
                ${name === selected && multiple === true ? activeBackColor : inactiveBackColor}
                ${name === selected && multiple === true ? activeForeColor : inactiveForeColor}
                ${name === selected && multiple === false ? activeBackColor : inactiveBackColor}
                ${name === selected && multiple === false ? activeForeColor : inactiveForeColor}`}
        >
            
            {text}
            
        </button>

    )

}

export default ButtonWithRoundedCornersAndText