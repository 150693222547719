import { useSelector } from "react-redux";

const MainPageSideBarInfoPanelOne = () => {

    const { 
        showSidebar
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showMainPageSideBarInfoPanelOne
    } = 
    useSelector(
        state => state.mainpage
    )

    return (
    
        showSidebar && showMainPageSideBarInfoPanelOne && (
        
            <>
            
                <div className="row-start-1 row-end-2 pt-2 bg-yellow-300">
                    
                    1

                </div>

                
                <div className="row-span-8 bg-blue-300">

                    2

                </div> 

                <div className="row-start-10 row-end-13 bg-purple-300">

                    3

                </div>

            </>
        ) 
    )
}

export default MainPageSideBarInfoPanelOne