import RoundLevelIndicator from '../shared/RoundLevelIndicator';

const ProductMenuModalListItem = ({
        backgroundColor, 
        color, 
        price, 
        stock, 
        minStockLevel, 
        parentStock, 
        parentMinStockLevel, 
        attributeOne,
        attributeTwo,
        currency
}) => {

    return (
        
        <button     
            className="
                w-full
                md:h-12
                1xl:h-12
                2xl:h-18
                md:rounded-md
                2xl:rounded-xl
                md:text-sm
                2xl:text-2xl
                border-2 
                border-solid 
                border-white
                bg-gray-300
                text-gray-700
                mb-2"
        >

            <div className="
                w-full 
                h-full 
                grid 
                grid-cols-12"
            >

                <div className={`
                    col-start-1 
                    col-end-4
                    flex
                    justify-center
                    items-center
                    md:rounded-md
                    2xl:rounded-xl
                    border-1 
                    border-solid 
                    border-white
                    ${backgroundColor}
                    ${color}`}
                >

                    {attributeOne}

                </div>

                <div className="
                    col-start-4 
                    col-end-7
                    flex
                    justify-center
                    items-center"
                >

                    {attributeTwo}

                </div>

                <div className="
                    col-start-7 
                    col-end-10
                    flex
                    justify-evenly
                    items-center"
                >

                    <RoundLevelIndicator 
                        stock={stock}
                        minStockLevel={minStockLevel}
                        useStockLevelColour={true}
                        showStockFigure={true}
                    />

                    <RoundLevelIndicator 
                        stock={parentStock}
                        minStockLevel={parentMinStockLevel}
                        useStockLevelColour={true}
                        showStockFigure={true}
                    />

                </div>

                <div className="
                    col-start-10 
                    col-end-13
                    flex
                    justify-center
                    items-center"
                >

                    {currency}{Number(price).toFixed(2)}

                </div>

            </div>

        </button>
    )
}

export default ProductMenuModalListItem