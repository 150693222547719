import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import MainPage from './pages/MainPage';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
            <Route exact path="/" element={<MainPage />}></Route>
        </Routes>
      </Router>
      
      <ToastContainer />
    </>
  );
}

export default App;
