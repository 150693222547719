import axios from "axios"
import ProductMenuData from "../../data/ProductMenuData"

let API_URL = "/dummybackend/api/product-menu/read.php";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
{
    API_URL = `${process.env.REACT_APP_API_SERVER}/dummybackend/api/product-menu/read.php`;
}

// Get Product Menu

const getProductMenu = async (token) => {

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }

    // const response = await axios.get(API_URL, config)
    const response = await axios.get(API_URL)

    return response.data

    // const response = ProductMenuData

    // return response

}

const productService = {
    getProductMenu
}

export default productService