import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StockLevelIndicatorWithParentStock from "../shared/StockLevelIndicatorWithParentStock";
import { FaBars } from "react-icons/fa";
import { toast } from "react-toastify"

import {
    setIsExpandedArray,
    setIsActiveArray,
    setIsActiveList,
    setIsActive,
    updateScannedItems,
    updateReset
} from "../../features/scanned-item/scannedItemSlice";

import { 
    setShowCollapsibleOne,
    setShowCollapsibleTwo,
    setShowCollapsibleThree
} from "../../features/mainpage/mainPageSlice";

const ScannedItemsListItem = ({
    id,
    position,
    description,
    image,
    price,
    totalPrice,
    qty,
    stock,
    minStockLevel,
    parentStock,
    parentMinStockLevel,
    currency
}) => {

    let [displayQty, setDisplayQty] = useState(parseInt(qty))
    let [displayTotalPrice, setDisplayTotalPrice] = useState(parseFloat(totalPrice))

    const imageUrl = process.env.REACT_APP_PRODUCT_IMAGE_DIRECTORY;

    const {
        scannedItems,
        isExpandedArray,
        isActiveArray,
        updateError,
        updateMessage
    } = 
    useSelector(
        state => state.scanneditem
    ) 

    const dispatch = useDispatch();

    useEffect(() => {

        // Blue Menu - Product Action
        
        const isActiveItems = isActiveArray.filter((item, index) => item === true)

        if(isActiveItems.length  > 0)
        {
            dispatch(setShowCollapsibleTwo(true))
        }
        else
        {
            dispatch(setShowCollapsibleTwo(false))
        }

        dispatch(setIsActiveList(isActiveArray.map((item, index) => item === true ? scannedItems[index].sku : "")))

        // Lime Menu - Edit Product

        const isActiveItem = isExpandedArray.findIndex((item, index) => item === true)

        if(isActiveItem !== -1)
        {
            dispatch(setShowCollapsibleOne(true))
            
            dispatch(setIsActive(scannedItems[isActiveItem].sku))
        }
        else
        {
            dispatch(setShowCollapsibleOne(false))
            
            dispatch(setIsActive(""))
        }

    }, [isActiveArray, isExpandedArray])

    useEffect(() => {

        if(updateError)
        {
            toast.error(updateMessage)
        }

        dispatch(updateReset())

    }, [updateError, dispatch])

    // Blue Menu - Product Action

    const handleClick = (e) => {

        dispatch(setShowCollapsibleOne(false))
        dispatch(setShowCollapsibleThree(false))
        dispatch(setIsExpandedArray(new Array(scannedItems.length).fill(false)))

        const updatedActiveState = isActiveArray.map((item, index) => index === position ? !item : item)

        dispatch(setIsActiveArray(updatedActiveState))

    }

    // Lime Menu - Edit Product

    const handleShowCollapsible = (e) => {

        dispatch(setShowCollapsibleTwo(false))
        dispatch(setIsActiveArray(new Array(scannedItems.length).fill(false)))

        const updatedCollapsibleState = isExpandedArray.map((item, index) => index === position ? !item : false );

        dispatch(setIsExpandedArray(updatedCollapsibleState))

    }

    const handlePlusMinusClick = (plusMinus) => {

        let updated = 0

        if(plusMinus === "plus")
        {
            displayQty += 1

            updated = 1
        }
        
        if(plusMinus === "minus" && displayQty > 1)
        {
            displayQty -= 1

            updated = 1
        }

        if(updated === 1)
        {      
            onMutate(displayQty)
        }

    }

    const onMutate = (updateQty) => {

        let value = 0

        if(updateQty)
        {
            value = parseInt(updateQty)
        }
        else
        {
            value = parseInt(value)
        }

        setDisplayQty(parseInt(value))

        totalPrice = price * value

        setDisplayTotalPrice(parseFloat(totalPrice))

        // console.log({id: id, qty: value, total_price: totalPrice})

        dispatch(updateScannedItems({id: id, qty: value, total_price: parseFloat(totalPrice)}))

    }

    return (

        <div className={`
            scanned-item
            ml-2
            ${isExpandedArray[position] ? "md:mr-[-2px] 1xl:mr-[-3px]" : "mr-2"}`}
        >

            <div className={`
                w-full
                md:h-24
                1xl:h-24
                2xl:h-32
                grid 
                grid-cols-12
                md:rounded-md
                2xl:rounded-xl
                border-2 
                border-solid 
                border-white
                md:text-sm
                1xl:text-sm
                2xl:text-xl
                mb-2
                ${isActiveArray[position] ? "bg-blue-400 text-white" : "bg-gray-200 text-gray-500"}`}
                id={id}
            >
                
                <div className="
                    col-start-1 
                    col-end-9"
                >

                    <button 
                        onClick={handleClick}
                        className="
                        w-full
                        h-full
                        grid
                        grid-cols-8"
                    >

                        <div className="
                            w-full
                            h-full
                            col-start-1 
                            col-end-4
                            flex
                            justify-center
                            items-center
                            p-2"
                        >
                            
                            <img
                                className="
                                    w-full
                                    h-full
                                    md:rounded-md
                                    2xl:rounded-xl
                                    object-scale-down"
                                alt=""
                                src={imageUrl + image}
                            />

                        </div>

                        <div className="
                            w-full
                            h-full
                            col-start-4 
                            col-end-9 
                            grid
                            grid-rows-2
                            md:p-1
                            2xl:p-2"
                        >
                            
                            <div className="
                                w-full
                                h-full
                                row-start-1 
                                row-end-2
                                flex
                                justify-start
                                truncate"
                            >
                                
                                {description}

                            </div>

                            <div className="
                                w-full
                                h-full
                                row-start-2 
                                row-end-3"
                            >
                                
                                <StockLevelIndicatorWithParentStock 
                                    stock={stock} 
                                    minStockLevel={minStockLevel}
                                    parentStock={parentStock}
                                    parentMinStockLevel={parentMinStockLevel}
                                />

                            </div>

                        </div>

                    </button>

                </div>

                <div className="
                    w-full
                    h-full
                    col-start-9 
                    col-end-12 
                    grid
                    grid-rows-3
                    pr-2"
                >
                    
                    <div className="
                        row-start-1 
                        row-end-2
                        flex
                        justify-end
                        items-center"
                    >

                        {currency}{Number(price).toFixed(2)}
                        
                    </div>

                    <div className="
                        row-start-2
                        row-end-3
                        flex
                        justify-end
                        items-center"
                    >

                        <button 
                            type="button" 
                            onClick={ 
                                (e) => { 
                                    e.stopPropagation()
                                    handlePlusMinusClick('minus')
                                }
                            } 
                            className="
                                rounded-tl-xl
                                rounded-bl-xl
                                bg-lime 
                                text-white 
                                w-1/3"
                        >
                            -
                        </button>
                        <input 
                            type="text" 
                            value={displayQty} 
                            onChange={(e) => onMutate(e.target.value)}
                            className={`
                                w-1/3 
                                text-center
                                border-none
                                outline-none
                                ${isActiveArray[position] && "text-gray-500"} `}
                        />
                        <button 
                            type="button" 
                            onClick={ 
                                (e) => { 
                                    e.stopPropagation()
                                    handlePlusMinusClick('plus')
                                } 
                            } 
                            className="
                                rounded-tr-xl
                                rounded-br-xl
                                bg-lime 
                                text-white
                                w-1/3"
                        >
                            +
                        </button>

                    </div>

                    <div className="
                        row-start-3
                        row-end-4
                        flex
                        justify-end
                        items-center
                        md:text-lg
                        1xl:text-lg
                        2xl:text-2xl"
                    >

                        {currency}{Number(displayTotalPrice).toFixed(2)}

                    </div>

                </div>

                <div className="
                    w-full
                    h-full
                    col-start-12
                    col-end-13"
                >
                    
                    <button 
                        onClick={handleShowCollapsible}                    
                        className="
                            w-full
                            h-full
                            md:rounded-tr-md
                            md:rounded-br-md
                            2xl:rounded-tr-xl
                            2xl:rounded-br-xl
                            flex
                            justify-center
                            items-center
                            bg-lime
                            text-white"
                    >

                        <FaBars />

                    </button>

                </div>

            </div>

        </div>

    )
}

export default ScannedItemsListItem