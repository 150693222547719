import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify"
import Layout1 from "../layouts/Layout1"
import UserItem from "./UserItem"
import Spinner from "../shared/Spinner"
import NumericKeyboard from "../shared/NumericKeyboard"
import FullKeyboard from "../shared/FullKeyboard"

import { 
    onLabelClick,
    onKeyDown,
    setShowUserPageView,
    setShowMainPageView,
    setKeyboardOutputValue,
    setSelectedLabel
} from "../../features/global/globalSlice";

import { 
    reset as userReset,
    getUsers,
    login
} from "../../features/users/userSlice";

const Users = () => {

    const [showNumericKeyboard, setShowNumericKeyboard] = useState(true)

    const { 
        showKeyboard,
        keyboardOutputValue
    } = 
    useSelector(
        state => state.global
    )

    const { users, user, selectedUser, isLoading, isSuccess, isError, message } = 
    useSelector(
        state => state.user
    )

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getUsers())

    }, [dispatch])

    useEffect(() => {

        if(isError)
        {
            toast.error(message)

            dispatch(setKeyboardOutputValue(""))
            dispatch(setSelectedLabel(""))

            dispatch(setShowUserPageView(true))
            dispatch(setShowMainPageView(false))
        }

        if(isSuccess && user)
        {
            dispatch(setKeyboardOutputValue(""))
            dispatch(setSelectedLabel(""))
            
            dispatch(setShowUserPageView(false))
            dispatch(setShowMainPageView(true))
        }

        dispatch(userReset()) 

    }, [user, isSuccess, isError, dispatch])

    const handleLogin = () => {

        dispatch(login(selectedUser, keyboardOutputValue))

    }

    const handleChange = () => {

        setShowNumericKeyboard(!showNumericKeyboard)

    }

    if (isLoading) 
    {
        return <Spinner />
    }

    return (

        <Layout1>

            {showKeyboard ? (
                
                <div className="h-full w-full grid grid-rows-6">

                    <div className="row-start-1 row-end-2 flex justify-end 2xl:text-2xl">

                        <label className="switch-1">
                            <input type="checkbox" onChange={handleChange}/>
                            <span className="slider-base-1 slider-4 round"></span>
                        </label>

                    </div>

                    <div className="h-full w-full flex flex-col justify-center items-center row-span-4">

                        <label 
                            onClick={(e) => dispatch(onLabelClick({targetID: e.target.id, innerHTML: e.target.innerHTML}))}
                            onKeyDown={(e) => dispatch(onKeyDown({key: e.key}))}
                            className={`
                                md:h-10 
                                1xl:h-12 
                                2xl:h-16
                                text-gray-700
                                bg-white bg-clip-padding
                                md:text-4xl 
                                1xl:text-5xl 
                                2xl:text-6xl
                                focus:outline-none 
                                focus:border-2
                                focus:border-solid 
                                focus:border-hot-pink
                                ${ showNumericKeyboard ? 'w-1/4' : 'w-3/4' } `}
                            tabIndex="0"
                            id="user-output"
                        >    
                        </label> 

                        <div className="flex flex-col justify-center items-center">

                            { showNumericKeyboard ? (

                                <NumericKeyboard 
                                    keybBtnStyle="
                                        flex 
                                        justify-center 
                                        items-center 
                                        md:w-14 
                                        md:h-14 
                                        1xl:w-16 
                                        1xl:h-16 
                                        2xl:w-24 
                                        2xl:h-24 
                                        md:text-4xl 
                                        1xl:text-5xl 
                                        2xl:text-6xl 
                                        focus:outline-none"     
                                />

                            ) : (

                                <FullKeyboard 
                                    keybBtnStyle="
                                        flex 
                                        justify-center 
                                        items-center 
                                        md:w-14 
                                        md:h-14 
                                        1xl:w-16 
                                        1xl:h-16 
                                        2xl:w-24 
                                        2xl:h-24 
                                        md:text-4xl 
                                        1xl:text-5xl 
                                        2xl:text-6xl 
                                        focus:outline-none"

                                    keybBtnStyle1="
                                        flex 
                                        justify-center 
                                        items-center 
                                        md:w-48 
                                        md:h-14 
                                        1xl:w-48 
                                        1xl:h-16 
                                        2xl:w-64 
                                        2xl:h-24 
                                        md:text-3xl 
                                        1xl:text-4xl 
                                        2xl:text-5xl 
                                        focus:outline-none"
                                />

                            )}

                        </div>

                    </div>

                    <div className="row-start-6 row-end-7 flex justify-center items-center">

                        <button 
                            type="button" 
                            onClick={handleLogin} 
                            className="
                                text-black
                                bg-white 
                                md:text-2xl 
                                xl:text-3xl 
                                2xl:text-5xl 
                                md:opacity-80 
                                xl:opacity-80 
                                2xl:opacity-80 
                                md:rounded-xl 
                                xl:rounded-xl 
                                2xl:rounded-2xl 
                                md:px-20 md:py-4 
                                xl:px-28 xl:py-6 
                                2xl:px-40 
                                2xl:py-9 
                                text-center"
                            >
                                ENTER
                            </button>

                    </div>

                </div>
                
            ) : (
                
                <div className="grid grid-cols-5 gap-x-20 gap-y-10">
                    {users.map((user) => (    
                        <UserItem 
                            key={user.id} 
                            user={user} 
                        />
                    ))}
                </div> 
            )}

        </Layout1>

    )
    
}

export default Users