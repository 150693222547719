import StockWithParentStockColour from "../../js/shared/StockWithParentStockColour"

const RoundLevelIndicator = ({
    stock, 
	minStockLevel,
    useStockLevelColour,
    colour,
    qty,
    showStockFigure
}) => {

    const stockString = StockWithParentStockColour(stock, minStockLevel, null, null)

    return (
        <div className={`
            md:w-8
            md:h-8
            1xl:w-8
            1xl:h-8
            2xl:w-12
            2xl:h-12
            flex
            justify-center
            items-center
            md:rounded-full
            md:text-xs
            1xl:text-sm
            2xl:text-lg
            ${useStockLevelColour ? stockString.stockClass : colour}`}
        >
            
            {showStockFigure ? stock : qty}

        </div>
    )
}

export default RoundLevelIndicator