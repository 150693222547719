const StockWithParentStockColour = (
    stock, 
	minStockLevel, 
	parentStock, 
	parentMinStockLevel
) => {

    let stockClass = "";
	let parentStockClass = "";
    
    if(stock <= 0)
    {
        stockClass = "bg-no-stock text-white";
    }
    else if(stock <= minStockLevel)
    {
        stockClass = "bg-min-stock text-white";
    }
    else if (stock >= minStockLevel)
    {
        stockClass = "bg-in-stock text-white";
    }

	if(parentStock <= 0)
    {
        parentStockClass = "bg-no-stock text-white";
    }
    else if(parentStock <= parentMinStockLevel)
    {
        parentStockClass = "bg-min-stock text-white";
    }
    else if (parentStock >= parentMinStockLevel)
    {
        parentStockClass = "bg-in-stock text-white";
    }

    return {
        stockClass: stockClass,
        parentStockClass: parentStockClass
    }
}

export default StockWithParentStockColour