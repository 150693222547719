const CircleCheckBoxWithText = ({checkedState, onChange, text, containerCssText, textCss}) => {

    return (

        <div
            className={`
            grid
            grid-cols-12
            ${containerCssText}`}
        >
            <div className="
                w-full
                h-full
                col-start-1
                col-end-3
                flex
                justify-center
                items-center"
            >
            
                <label className="switch-3">
                    <input 
                        type="checkbox"
                        checked={checkedState}
                        onChange={onChange} 
                    />
                    <span className="slider-6"></span>
                </label>

            </div>

            <div className={`
                w-full
                h-full
                col-span-10
                md:text-xl
                2xl:text-3xl
                flex
                items-center
                ${textCss}`}
            >
                
                {text}

            </div> 

        </div>

    )

}

export default CircleCheckBoxWithText