import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const user = JSON.parse(localStorage.getItem("user"))

const initialState = {
    users: [],
    user: user ? user: null,
    selectedUser: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

// Get users

export const getUsers = createAsyncThunk("users/getUsers", async (_, thunkAPI) => {

    try {

        return await userService.getUsers()

    } catch (error) {

        const message = (
            error.response && 
            error.response.data && 
            error.response.data.message
        ) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }

})

// Selected user

export const selectUser = createAsyncThunk("users/selectUser", async (user, thunkAPI) => {

    await userService.selectUser(user)

    return user

})

// Login user

export const login = createAsyncThunk("users/login", async (user, thunkAPI) => {

    try {

        const password = thunkAPI.getState().global.keyboardOutputValue

        return await userService.login(user, password)

    } catch (error) {

        const message = (
            error.response && 
            error.response.data && 
            error.response.data.message
        ) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }

})

// Logout user

export const logout = createAsyncThunk("auth/logout", async (user, thunkAPI) => {
    
    await userService.logout()

})

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading =  false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getUsers.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.users = action.payload
        })
        .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(selectUser.fulfilled, (state, action) => {
            state.selectedUser = action.payload
        })
        .addCase(login.pending, (state) => {
            state.isLoading = true
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.selectedUser = null
            state.user = action.payload
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.selectedUser = null
            state.user = null
        })
    }
})

export const { 
    reset
} = userSlice.actions

export default userSlice.reducer