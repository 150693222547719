import { createSlice } from "@reduxjs/toolkit";
import handleKeyboardDown from "../../js/shared/HandleKeyboardDown";
import handleKeyboardClick from "../../js/shared/HandleKeyboardClick";
import isOS from "../../js/shared/CheckOS";

const initialState = {
    showKeyboard: false,
    selectedLabel: "",
    keyboardOutputValue: "",
    showSidebar: false,
    showUserPageView: true,
    showMainPageView: false,
    showCustomerSearchView: false,
    showProductSearchView: false,
    showOrderView: false,
    isWindows: false,
    isAndroid: false
}

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        reset: (state) => initialState,
        setShowKeyboard: (state, action) => {
            state.showKeyboard = action.payload
        },
        onLabelClick: (state, action) => {
            state.selectedLabel = action.payload.targetID
            state.keyboardOutputValue = action.payload.innerHTML
        },
        onKeyDown: (state, action) => { 
            let value = handleKeyboardDown(action.payload.key, state.selectedLabel)

            state.keyboardOutputValue = value
        },
        onKeyClick: (state, action) => {   
            let value = handleKeyboardClick(
                action.payload.keyType, 
                action.payload.innerHTML, 
                state.selectedLabel
            )

            state.keyboardOutputValue = value
        },
        checkOS: (state, action) => {  
            const { isWindows, isAndroid } = isOS()

            state.isWindows = isWindows
            state.isAndroid = isAndroid
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload
        },
        setShowUserPageView: (state, action) => {
            state.showUserPageView = action.payload
        },
        setShowMainPageView: (state, action) => {
            state.showMainPageView = action.payload
        },
        setShowCustomerSearchView: (state, action) => {
            state.showCustomerSearchView = action.payload
        },
        setShowProductSearchView: (state, action) => {
            state.showProductSearchView = action.payload
        },
        setShowOrderView: (state, action) => {
            state.showOrderView = action.payload
        },
        setKeyboardOutputValue: (state, action) => {
            state.keyboardOutputValue = action.payload
        },
        setSelectedLabel: (state, action) => {
            state.selectedLabel = action.payload
        },
        
    },
    extraReducers: (builder) => {

    }
})

export const { 
    reset,
    setShowKeyboard,
    onLabelClick,
    onKeyDown,
    onKeyClick,
    checkOS,
    setShowSidebar,
    setShowUserPageView,
    setShowMainPageView,
    setShowCustomerSearchView,
    setShowProductSearchView,
    setShowOrderView,
    setKeyboardOutputValue,
    setSelectedLabel
} = globalSlice.actions

export default globalSlice.reducer