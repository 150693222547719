import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import largeTimes from '../assets/large-times.svg'
import { productMenuModalSideBarList } from "../../data/ProductMenuModalSideBarList"
import CircleCheckBoxWithText from '../shared/CircleCheckBoxWithText'

import { 
    setShowProductMenuModalSideBar
} from "../../features/product-menu/productMenuSlice";

const ProductMenuModalSideBar = () => {

    const [checkedState, setCheckedState] = useState(
        new Array(productMenuModalSideBarList.length).fill(true)
    );

    const { 
        showProductMenuModalSidebar
    } = 
    useSelector(
        state => state.productmenu
    )

    const dispatch = useDispatch();

    const handleModalOneSideBarClose = () => {

        dispatch(setShowProductMenuModalSideBar(!showProductMenuModalSidebar))

    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
    
        setCheckedState(updatedCheckedState);      
    }

    return (
        <div 
            className={`
                z-[1000]
                h-full
                absolute
                top-0
                right-0
                p-2
                ${
                    showProductMenuModalSidebar 
                    ? "w-1/2" 
                    : "hidden"
                }`
            }
        >
        
            <div className="
                w-full 
                h-full
                opacity-90
                bg-slate-900"
            >
                
                <div className="
                    w-full 
                    h-full 
                    grid 
                    grid-rows-10
                    md:p-2
                    2xl:p-4"
                >

                    <div className="
                        w-full
                        h-full
                        row-start-1
                        row-end-2"
                    >

                        <div className="
                            flex
                            justify-between
                            items-center
                            border-b-2"
                        >

                            <div className="
                                md:text-2xl
                                2xl:text-4xl"
                            >
                                Customer List options....
                            </div>

                            <div>

                            <button
                                onClick={handleModalOneSideBarClose}
                            >
                                <img
                                    className="modal-one-times-large-fa text-white"
                                    alt="Close"
                                    src={largeTimes}
                                />
                            </button>

                            </div>

                        </div>

                    </div>

                    <div className="
                        w-full
                        h-full
                        row-span-9"
                    >

                        <div className="
                            flex
                            flex-col"
                        >

                            {productMenuModalSideBarList.map(({ name }, index) => {

                                return (

                                    <CircleCheckBoxWithText 
                                        key={index} 
                                        checkedState={checkedState[index]}
                                        onChange={() => handleOnChange(index)}
                                        text={name}
                                        containerCssText={'md:h-16 2xl:h-24'}
                                    />

                                )
                                
                            })}

                        </div>                  

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ProductMenuModalSideBar