import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showCustomerSearchViewSideBarInfo: false
}

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setShowCustomerSearchViewSideBarInfo: (state, action) => {
            state.showCustomerSearchViewSideBarInfo = action.payload
        }
    }
})

export const { 
    setShowCustomerSearchViewSideBarInfo,
} = customerSlice.actions

export default customerSlice.reducer