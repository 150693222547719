import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify"
import ScannedItemsListItem from "./ScannedItemsListItem"
import ImageGridItem from "../shared/ImageGridItem";
import Spinner from "../shared/Spinner";

import { 
    getScannedItems,
    reset,
    setIsExpandedArray,
    setIsActiveArray,
    setIsActiveList,
    setShowScannedItemsListView,
    setIsListChange,
    setIsActive
} from "../../features/scanned-item/scannedItemSlice";

import { 
    setShowCollapsibleOne,
    setShowCollapsibleTwo,
    setShowCollapsibleThree
} from "../../features/mainpage/mainPageSlice";

const ScannedItems = () => {

    const [scrollTo, setScrollTo] = useState('')

    const { 
        scannedItems, 
        isLoading, 
        isSuccess, 
        isError, 
        message,
        showScannedItemsListView,
        isListChange
    } = 
    useSelector(
        state => state.scanneditem
    )

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getScannedItems())

    }, [dispatch, isListChange])

    useEffect(() => {

        if(isError)
        {
            toast.error(message)
        }

        if(isSuccess && !isListChange)
        {
            dispatch(setIsExpandedArray(new Array(scannedItems.length).fill(false))) 
            
            dispatch(setIsActiveArray(new Array(scannedItems.length).fill(false))) 
        }

        if(isSuccess && isListChange)
        {
            if(scrollTo)
            {            
                const scannedListItem = document.getElementById(scrollTo)

                scannedListItem.scrollIntoView( { behavior: 'smooth'} )

                setScrollTo("")
            }
        }

        dispatch(reset())

    }, [isSuccess, isError, dispatch])

    const handleChange = () => {

        dispatch(setShowScannedItemsListView(!showScannedItemsListView))

        dispatch(setIsListChange(true))

        if(showScannedItemsListView)
        {
            dispatch(setShowCollapsibleOne(false))

            dispatch(setShowCollapsibleTwo(false))

            dispatch(setShowCollapsibleThree(false))

            dispatch(setIsExpandedArray(new Array(scannedItems.length).fill(false)))

            dispatch(setIsActive(""))

            dispatch(setIsActiveArray(new Array(scannedItems.length).fill(false))) 

            dispatch(setIsActiveList(new Array(scannedItems.length).fill("")))
        }

    }

    const handleClick = (e) => {

        dispatch(setShowScannedItemsListView(!showScannedItemsListView))

        dispatch(setIsListChange(true))

        setScrollTo(e.currentTarget.id)

    }

    if (isLoading) 
    {
        return <Spinner />
    }

    return (
        <>
            <div className="
                w-full 
                h-full
                row-start-1 
                row-end-2
                p-1"
            >
                
                <div className="
                    w-full
                    h-full
                    grid 
                    grid-cols-12"
                >

                    <div className="
                        col-start-1 
                        col-end-8
                        1xl:p-2
                        2xl:p-2"
                    >
                        <div className="
                            w-full
                            h-full
                            flex 
                            justify-center 
                            items-center"
                        >
                            <div className="toggle_radio">

                                <input type="radio" className="toggle_option" value="0" id="scanned-items-filter_all" name="toggle_option" />
                                <input type="radio" className="toggle_option" value="1" id="scanned-items-filter_order" name="toggle_option" />
                                <input type="radio" className="toggle_option" value="2" id="scanned-items-filter_collect" name="toggle_option" />
                                <label htmlFor="scanned-items-filter_all" className="md:text-xs 2xl:text-lg">ALL</label>
                                <label htmlFor="scanned-items-filter_order" className="md:text-xs 2xl:text-lg">ORDER</label>
                                <label htmlFor="scanned-items-filter_collect" className="md:text-xs 2xl:text-lg">COLLECT</label>
                                <div className="toggle_option_slider"></div>

                            </div>

                        </div>

                    </div>

                    <div className="
                        col-start-8 
                        col-end-10
                        flex
                        justify-center
                        items-center"
                    >

                        M

                    </div>

                    <div className="
                        col-start-10 
                        col-end-13
                        flex
                        justify-center
                        items-center"
                    >

                        <label className="switch-2">
                            <input type="checkbox" onChange={handleChange} checked={showScannedItemsListView}/>
                            <span className="slider-base-2 slider-5 round"></span>
                        </label>

                    </div>

                </div>

            </div>

            <div className={`
                w-full
                h-full
                row-span-11
                overflow-y-auto 
                ${
                    showScannedItemsListView 
                    ? "gap-2" 
                    : "flex flex-wrap pl-2 md:gap-2 1xl:gap-3 2xl:gap-4" 
                }`}
            >
                {
                    scannedItems.map((items, index) => ( 

                        showScannedItemsListView ? (

                            <ScannedItemsListItem 
                                key={items.id} 
                                id={items.id}
                                position={index}
                                description={items.description} 
                                image={items.image}
                                price={items.price}
                                totalPrice={items.total_price}
                                qty={items.qty}
                                stock={items.stock_qty}
                                minStockLevel={items.min_stock_qty}
                                parentStock={items.parent_stock_qty}
                                parentMinStockLevel={items.parent_min_stock_qty}
                                currency={'£'}
                            />

                        ) : (

                            <ImageGridItem 
                                onClick={(e) => handleClick(e)}
                                id={items.id}
                                key={items.id} 
                                description={items.description} 
                                image={items.image}
                                wh={'md:w-24 md:h-28 2xl:w-36 2xl:h-40'}
                                bgDescriptionColor={'bg-gray-200'}
                                bgTextColor={'text-gray-700'}
                                qty={items.qty}
                                showQty={true}
                                centered={true}
                            />

                        )

                    ))
                }

            </div>
        </>
    )

}

export default ScannedItems