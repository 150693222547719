import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import scannedItemService from "./scannedItemService";

const initialState = {
    scannedItems: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    isExpandedArray: [],
    isActiveArray: [],
    isActiveList: [],
    isActive: '',
    updateError: false,
    updateMessage: "",
    showScannedItemsListView: false,
    isListChange: false
}

// Get scanned items

export const getScannedItems = createAsyncThunk("scannedItems/getScannedItems", async (_, thunkAPI) => {

    try {

        return await scannedItemService.getScannedItems()

    } catch (error) {

        const message = (
            error.response && 
            error.response.data && 
            error.response.data.message
        ) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }

})

// Create new ticket

export const updateScannedItems = createAsyncThunk("scanneditem/updateScannedItems", async (data, thunkAPI) => {

    try {

        // const token = thunkAPI.getState().auth.user.token
        
        // return await ticketService.createTicket(ticketData, token)

        // const token = thunkAPI.getState().auth.user.token
        
        return await scannedItemService.updateScannedItems(data)

    } catch (error) {

        const message = (
            error.response && 
            error.response.data && 
            error.response.data.message
        ) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }

})

export const scannedItemSlice = createSlice({
    name: "scanneditem",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading =  false
            state.isError = false
            state.isSuccess = false
            state.message = ""
            state.isListChange = false
        }, 
        setIsExpandedArray: (state, action) => {
            state.isExpandedArray = action.payload
        },
        setIsActiveArray: (state, action) => {
            state.isActiveArray = action.payload
        },
        setIsActiveList: (state, action) => {
            state.isActiveList = action.payload
        },
        setIsActive: (state, action) => {
            state.isActive = action.payload
        },
        updateReset: (state) => {
            state.updateError = false
            state.updateMessage = ""
        },
        setShowScannedItemsListView : (state, action) => {
            state.showScannedItemsListView = action.payload
        },
        setIsListChange : (state, action) => {
            state.isListChange = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getScannedItems.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getScannedItems.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.scannedItems = action.payload
        })
        .addCase(getScannedItems.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateScannedItems.rejected, (state, action) => {
            state.updateError = true
            state.updateMessage = action.payload
        })  
    }
})

export const { 
    reset,
    setIsExpandedArray,
    setIsActiveArray,
    setIsActiveList,
    setIsActive,
    updateReset,
    setShowScannedItemsListView,
    setIsListChange
} = scannedItemSlice.actions

export default scannedItemSlice.reducer