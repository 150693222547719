import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layout2 from "../components/layouts/Layout2"
import UserView from "../components/users/UserView"
import MainPageView from "../components/mainpage/MainPageView"
import CustomerSearchView from "../components/customers/CustomerSearchView"
import ProductSearchView from "../components/products/ProductSearchView"
import OrderView from "../components/order/OrderView";

import { checkOS } from "../features/global/globalSlice";

const MainPage = () => {

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(checkOS())

  }, [])

  return (
    <Layout2>  
      <UserView />
      <MainPageView />
      <CustomerSearchView />
      <ProductSearchView />
      <OrderView />
    </Layout2>
  )
}

export default MainPage