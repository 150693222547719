import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getProductMenu, reset } from "../../features/products/productSlice";
import ProductMenuListItem from "./ProductMenuListItem";
import { toast } from "react-toastify"
import Spinner from "../shared/Spinner";
import homeIcon from "../assets/home-icon.svg"
import ImageGridItem from "../shared/ImageGridItem";

import { 
    setShowProductMenuModal,
    setShowProductMenuListView
} from "../../features/mainpage/mainPageSlice";

const ProductMenu = () => {

    const { 
        showProductMenuListView,
        showProductMenuModal
    } = 
    useSelector(
        state => state.mainpage
    )
    
    const { 
        productMenu, 
        isLoading, 
        isSuccess, 
        isError, 
        message 
    } = 
    useSelector(
        state => state.product
    )

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getProductMenu())

    }, [dispatch])

    useEffect(() => {

        if(isError)
        {
            toast.error(message)
        }

        if(isSuccess)
        {
            dispatch(reset())
        }

    }, [isSuccess, isError, dispatch])

    const handleChange = () => {

        dispatch(setShowProductMenuListView(!showProductMenuListView))

    }

    const handleClick = () => {

        dispatch(setShowProductMenuModal(!showProductMenuModal))

    }

    if (isLoading) 
    {
        return <Spinner />
    }

    return (
        <>
            <div className="
                p-2
                row-start-1 
                row-end-2 
                w-full 
                h-full 
                flex 
                justify-between 
                items-center">
            
                <div className="
                    flex
                    md:text-sm
                    1xl:text-xl
                    2xl:text-2xl"
                >
                    <img 
                        src={homeIcon} 
                        alt="Home Icon" 
                        className="home-icon" 
                    /> 
                    &nbsp; &gt; Household &nbsp; &gt; Lamps 
                </div>
                
                <div className="
                    flex
                    justify-end"
                >
                    
                    <label className="switch-2">
                        <input type="checkbox" onChange={handleChange} checked={showProductMenuListView} />
                        <span className="slider-base-2 slider-5 round"></span>
                    </label>
                        
                </div>

            </div>

            <div className="
                w-full
                h-full
                row-span-11
                overflow-y-auto"
            >

                <div 
                    className={`
                        p-2 
                        flex 
                        flex-wrap 
                        ${
                            showProductMenuListView 
                            ? "gap-2" 
                            : "md:gap-2 1xl:gap-3 2xl:gap-4" 
                        }`
                    }
                >

                    {productMenu.map((items) => (   

                        showProductMenuListView ? (

                            <ProductMenuListItem 
                                key={items.id} 
                                description={items.description} 
                                image={items.image}
                                price={items.price}
                                stock={items.stock_qty}
                                minStockLevel={items.min_stock_qty}
                                currency={'£'}
                            />

                        ) : (

                            <ImageGridItem 
                                onClick={handleClick}
                                key={items.id} 
                                description={items.description} 
                                image={items.image}
                                wh={'md:w-24 md:h-28 2xl:w-36 2xl:h-40'}
                                bgDescriptionColor={'bg-gray-200'}
                                bgTextColor={'text-gray-700'}
                                centered={true}
                            />

                        )
                        
                    ))}

                </div>

            </div>
        </>
    )
}

export default ProductMenu