import backButton from '../assets/back-button.svg'
import largeTimes from '../assets/large-times.svg'

const Layout3 = ({ 
    onClick, 
    mainClass,
    headerText, 
    headerBackgroundColor, 
    bodyBackgroundColor,
    bodyForegroundColor,
    children }) => {

    return (

        <div    
            className={`
                ${mainClass}
                grid 
                grid-rows-12 
                text-white`}
        >
            
            <div className={`
                h-full
                w-full 
                row-start-1 
                row-end-2 
                grid 
                grid-cols-12
                ${headerBackgroundColor}`}
            >
            
                <div className="
                    col-start-1 
                    col-end-12 
                    flex 
                    items-center 
                    pl-2"
                >

                    <div className="
                        w-full
                        h-full
                        flex
                        items-center"
                    >
                    
                        <div 
                            className="
                            flex
                            items-center"
                        >
                            
                            <button
                                onClick={onClick}
                                className="
                                pl-2
                                w-full
                                h-full"
                            >
                                
                                <img                                               
                                    className="
                                        modal-one-back-button-fa 
                                        text-white"
                                    alt="Close"
                                    src={backButton}
                                /> 

                            </button>

                        </div>

                        <div 
                            className="
                            w-full
                            h-full
                            flex
                            items-center
                            md:text-2xl
                            2xl:text-4xl
                            pl-5
                            text-white"
                        >

                            {headerText}

                        </div>

                    </div>

                </div>

                <div className="col-start-12 col-end-13">

                    <div className="
                        w-full
                        h-full 
                        flex 
                        justify-end 
                        items-center
                        pr-2"
                    >
                        
                        <button
                            onClick={onClick}
                        >
                            
                            <img
                                className="
                                    modal-one-times-large-fa 
                                    text-white"
                                alt="Close"
                                src={largeTimes}
                            />
                            
                        </button>
                        
                    </div>

                </div>
            
            </div>

            <div className={`
                w-full 
                h-full 
                row-start-2 
                row-end-13 
                grid 
                grid-cols-12
                ${bodyBackgroundColor}
                ${bodyForegroundColor}`}
            >

                {children}

            </div>

        </div>

    )
}

export default Layout3