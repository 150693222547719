import { useSelector, useDispatch } from "react-redux";
import { FaBars } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'

import { 
    setShowSidebar,
    setShowMainPageView,
    setShowCustomerSearchView,
    setShowProductSearchView
} from "../../features/global/globalSlice";

import {
    setShowMainPageSideBarInfoPanelOne,
    setShowMainPageSideBarInfoPanelTwo
} from "../../features/mainpage/mainPageSlice"

const MainPageSideBar = () => {

    const { 
        showSidebar
    } = 
    useSelector(
        state => state.global
    )

    const dispatch = useDispatch();

    return (

        !showSidebar && (

            <>

                <div className="row-start-1 row-end-3 pt-2 text-center">
                    
                    <button 
                        onClick={() => { 
                            dispatch(setShowSidebar(!showSidebar)); 
                            dispatch(setShowMainPageSideBarInfoPanelOne(true)); 
                            dispatch(setShowMainPageSideBarInfoPanelTwo(false)); 
                        } }
                    >
                        { !showSidebar && <FaBars className="bars-fa" /> }
                    </button>

                </div>

                <div className="flex row-start-3 row-end-4 justify-center items-start">
                    
                    <button
                        onClick={() => { 
                            dispatch(setShowSidebar(!showSidebar)); 
                            dispatch(setShowMainPageSideBarInfoPanelOne(false)); 
                            dispatch(setShowMainPageSideBarInfoPanelTwo(true)); 
                        } }
                    >
                        <FaUser className="user-fa" />
                    </button>

                </div> 

                <div className="
                    w-full 
                    h-full 
                    row-start-4 
                    row-end-7 
                    hover:rounded-tl-2xl 
                    hover:rounded-bl-2xl 
                    hover:bg-lime"
                >

                    <div
                        onClick={ () => { 
                            dispatch(setShowCustomerSearchView(true)); 
                            dispatch(setShowMainPageView(false)) 
                        } }
                        className="
                            w-full 
                            h-full 
                            flex 
                            justify-center 
                            items-center 
                            md:text-xl
                            1xl:text-xl
                            2xl:text-3xl 
                            cursor-pointer 
                            sidebar-btn"
                    >
                        Customer Search
                    </div>

                </div>

                <div className="row-start-7 row-end-8"></div>

                <div className="
                    row-start-8 
                    row-end-11 
                    hover:rounded-tl-2xl 
                    hover:rounded-bl-2xl 
                    hover:bg-hot-pink"
                >

                    <div 
                        onClick={() => { 
                            dispatch(setShowProductSearchView(true)); 
                            dispatch(setShowMainPageView(false)) 
                        } }
                        className="
                            w-full 
                            h-full 
                            flex 
                            justify-center 
                            items-center 
                            md:text-xl
                            1xl:text-xl
                            2xl:text-3xl  
                            cursor-pointer 
                            sidebar-btn"
                    > 
                        Product Search
                    </div>

                </div>

            </> 
        )

    )
}

export default MainPageSideBar