import { useDispatch } from "react-redux";
import { onKeyClick } from "../../features/global/globalSlice";

const FullKeyboard = ({ keybBtnStyle, keybBtnStyle1 }) => {

    const dispatch = useDispatch()

    const handleKeyboardClick = (e) =>{

        const keyType = e.target.getAttribute('data-keytype')

        dispatch(onKeyClick({
            keyType: keyType, 
            innerHTML: e.currentTarget.innerHTML
        }))

    }

    return (

        <>

            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">1</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">2</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">3</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">4</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">5</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">6</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">7</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">8</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">9</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">0</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">q</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">w</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">e</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">r</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">t</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">y</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">u</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">i</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">o</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">p</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">a</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">s</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">d</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">f</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">g</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">h</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">j</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">k</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">l</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">z</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">x</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">c</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">v</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">b</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">n</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">m</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle}>&nbsp;</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle}>&nbsp;</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle1} data-keytype="space">___________</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">-</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="delete">&lt;</button>
            </div>

        </>

    )

}

export default FullKeyboard