const Layout2 = ({ children }) => {

    const backgroundImage = process.env.REACT_APP_LOGIN_BACKGROUND

    return (
        <div 
            style={{ 
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `
                    linear-gradient(rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)), 
                    url('${backgroundImage}')
                `
            }}
        >

            <div className="
                w-full 
                h-full 
                backdrop-blur-sm"
            >

                { children }

            </div>

        </div>
    )
}

export default Layout2