import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import largeTimes from '../assets/large-times.svg'
import StockContainer from "./StockContainer";
import StoreStockContainer from "./StoreStockContainer";
import ImageGridItem from "./ImageGridItem";
import CircleButtonOne from "./CircleButtonOne";
import CircleCheckBoxWithText from "./CircleCheckBoxWithText";
import { FaTrash } from 'react-icons/fa';
import ModalOne from "./ModalOne";
import FullKeyboard from '../shared/FullKeyboard';
import ButtonWithRoundedCornersAndText from "./ButtonWithRoundedCornersAndText";

import { 
    onLabelClick,
    onKeyDown,
    setKeyboardOutputValue,
    setSelectedLabel
} from "../../features/global/globalSlice";

import { 
    setShowCollapsibleOne,
    setShowCollapsibleThree
} from "../../features/mainpage/mainPageSlice";

import { 
    setIsActive,
    setIsExpandedArray 
} from "../../features/scanned-item/scannedItemSlice";

const CollapsibleOne = () => {

    const [groupStockExpand, setGroupStockExpand] = useState(false)
    const [showAddNote, setShowAddNote] = useState(false)
    const [selected, setSelected] = useState("")
    
    const { 
        keyboardOutputValue
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showCollapsibleOne,
        showCollapsibleThree
    } = 
    useSelector(
        state => state.mainpage
    )

    const { 
        scannedItems,
        isActive
    } = 
    useSelector(
        state => state.scanneditem
    ) 

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(setKeyboardOutputValue(""))

        dispatch(setSelectedLabel(""))

    }, [dispatch])

    const closeCollapsibleOne = () => {

        dispatch(setShowCollapsibleOne(false))

        dispatch(setShowCollapsibleThree(false))

        dispatch(setIsExpandedArray(new Array(scannedItems.length).fill(false)))

        dispatch(setIsActive(""))

    }

    const handleGroupStock = () => {

        setGroupStockExpand(!groupStockExpand)

    }

    const handleAddProcessClick = () => {

        dispatch(setShowCollapsibleThree(!showCollapsibleThree))

    }

    const handleAddNoteClick = () => {

        setShowAddNote(!showAddNote)

        dispatch(setShowCollapsibleThree(false))

    }

    const handModalOneButtonClick = (name) => {
       
        setSelected(name !== selected ? name : "")

    }

    const handleModalOneSaveClick = ()=> {

        // TODO:

        setShowAddNote(false)

        console.log(selected, keyboardOutputValue)

    }

    return (

        <>

            <div 
                className={`
                    absolute 
                    h-full 
                    top-0 
                    left-0 
                    bg-lime 
                    text-white
                    md:rounded-tr-2xl
                    md:rounded-br-2xl
                    2xl:rounded-tr-3xl
                    2xl:rounded-br-3xl
                    ${showCollapsibleOne ? "w-3/4 transition-[width] duration-500" : "hidden transition-[width] duration-500"} `}
            >
            
                <div className="
                    w-full 
                    h-full 
                    grid 
                    grid-rows-12">

                    {/* TODO: {isActive} has sku stored which will need to be used to call data to populate this menu */}
                    
                    <div className="
                        w-full
                        h-full
                        row-start-1 
                        row-end-2"
                    >
                        
                        <div className="
                            w-full
                            h-full
                            flex
                            justify-between
                            items-center
                            p-4"
                        >

                            <p className="
                                md:text-xl
                                1xl:text-2xl
                                2xl:text-3xl"
                            >
                                Edit Product
                            </p>

                            <button
                                onClick={closeCollapsibleOne}
                            >
                                <img
                                    className="edit-product-times-large-fa text-white"
                                    alt="Close"
                                    src={largeTimes}
                                />
                            </button>

                        </div>
                        
                    </div>

                    <div className="
                        w-full
                        h-full
                        row-start-2 
                        row-end-7
                        p-2
                        flex
                        overflow-y-scroll"
                    >
                        
                        <div className="
                            w-full
                            h-full"
                        > 
                            
                            <StockContainer 
                                stock={12}
                                minStockLevel={1}
                                useStockLevelColour={true}
                                text={'Local Stock'}
                            />
            
                            <StockContainer 
                                onClick={handleGroupStock}
                                stock={256}
                                useStockLevelColour={false}
                                colour={'bg-slate-500 text-white'}
                                text={'Group Stock'}
                            />

                            <div className={`
                                w-full
                                pb-2
                                ${groupStockExpand ? "md:max-h-24 1xl:max-h-32 2xl:max-h-44 transition-[height] duration-500" : "hidden transition-[height] duration-500"} 
                                overflow-y-scroll`}
                            >
                                <StoreStockContainer 
                                    stock={84}
                                    storeName={'Head Office'}
                                />
                                <StoreStockContainer 
                                    stock={78}
                                    storeName={'Bournemouth'}
                                />
                                <StoreStockContainer 
                                    stock={74}
                                    storeName={'Southampton'}
                                />
                                <StoreStockContainer 
                                    stock={12}
                                    storeName={'New Milton'}
                                />
                                <StoreStockContainer 
                                    stock={24}
                                    storeName={'Newbury'}
                                />
                                <StoreStockContainer 
                                    stock={19}
                                    storeName={'Westbourne'}
                                />
                                <StoreStockContainer 
                                    stock={7}
                                    storeName={'Weymouth'}
                                />
                                <StoreStockContainer 
                                    stock={2}
                                    storeName={'Lymington'}
                                />
                                <StoreStockContainer 
                                    stock={0}
                                    storeName={'Shirley'}
                                />
                                <StoreStockContainer 
                                    stock={22}
                                    storeName={'Portsmouth'}
                                />
                            </div>

                            <StockContainer 
                                stock={24}
                                useStockLevelColour={false}
                                colour={'bg-white text-black'}
                                text={'On Purchase Order'}
                            />

                            <StockContainer 
                                stock={3}
                                useStockLevelColour={false}
                                colour={'bg-white text-black'}
                                text={'On Sales Order'}
                            />

                        </div>

                    </div>

                    <div className="
                        w-full
                        h-full
                        row-start-7 
                        row-end-9
                        grid
                        grid-rows-6"
                    >
                        
                        <div className="
                            w-full
                            h-full
                            row-start-1 
                            row-end-2
                            flex
                            items-center
                            md:text-sm
                            2xl:text-xl
                            p-1"
                        >

                            Optional Related Products

                        </div>
                        
                        <div className="
                            w-full
                            h-full
                            row-span-5
                            flex
                            overflow-x-scroll"
                        >
                            
                            <div className="
                                p-1"
                            >

                                <ImageGridItem 
                                    key={1} 
                                    description={'Lamp - Birds'} 
                                    image={'3101449-r-z001a@2x.png'}
                                    wh={'md:w-18 md:h-22 2xl:w-28 2xl:h-32'}
                                    bgDescriptionColor={'bg-lime-light'}
                                    bgTextColor={'text-gray-500'}
                                    centered={false}
                                />

                            </div>

                            <div className="
                                p-1"
                            >

                                <ImageGridItem 
                                    key={2} 
                                    description={'Lamp - Brassey'} 
                                    image={'8490863-r-set@2x.png'}
                                    wh={'md:w-18 md:h-22 2xl:w-28 2xl:h-32'}
                                    bgDescriptionColor={'bg-lime-light'}
                                    bgTextColor={'text-gray-500'}
                                    centered={false}
                                />

                            </div>

                            <div className="
                                p-1"
                            >

                                <ImageGridItem 
                                    key={3} 
                                    description={'Yellow Lamp'} 
                                    image={'9159512-r-z001a@2x.png'}
                                    wh={'md:w-18 md:h-22 2xl:w-28 2xl:h-32'}
                                    bgDescriptionColor={'bg-lime-light'}
                                    bgTextColor={'text-gray-500'}
                                    centered={true}
                                />

                            </div>

                            <div className="
                                p-1"
                            >

                                <ImageGridItem 
                                    key={4} 
                                    description={'Nice Lamp'} 
                                    image={'9563603-r-z001a@2x.png'}
                                    wh={'md:w-18 md:h-22 2xl:w-28 2xl:h-32'}
                                    bgDescriptionColor={'bg-lime-light'}
                                    bgTextColor={'text-gray-500'}
                                    centered={false}
                                />

                            </div>

                            <div className="
                                p-1"
                            >

                                <ImageGridItem 
                                    key={5} 
                                    description={'Cow Lamp'} 
                                    image={'1645550008-12-7565@2x.png'}
                                    wh={'md:w-18 md:h-22 2xl:w-28 2xl:h-32'}
                                    bgDescriptionColor={'bg-lime-light'}
                                    bgTextColor={'text-gray-500'}
                                    centered={false}
                                />

                            </div>

                        </div>

                    </div>

                    <div className="
                        w-full
                        h-full
                        row-start-9 
                        row-end-11
                        p-1
                        flex
                        justify-between
                        items-center"
                    >

                        <CircleButtonOne 
                            onClick={handleAddNoteClick}
                            cssText={'bg-lime-light text-blue-400'} 
                            text={'Add Note'} 
                        />

                        <CircleButtonOne 
                            cssText={'bg-lime-light text-blue-400'} 
                            text={'Add Ticket'} 
                        />

                        <CircleButtonOne 
                            onClick={handleAddProcessClick}
                            cssText={'bg-lime-light text-blue-400'} 
                            text={'Add Process'} 
                        />
                    
                    </div>

                    <div className="
                        w-full
                        h-full
                        row-start-11 
                        row-end-12
                        flex
                        justify-center
                        items-center"
                    >

                        <CircleCheckBoxWithText 
                            text={'Sell excluding VAT'}
                            textCss={'md:pl-2 1xl:pl-4'}
                        />

                    </div>

                    <div className="
                        row-start-12 
                        row-end-13
                        w-full
                        h-full
                        flex
                        justify-center
                        items-center"
                    >

                        <button className="
                            md:h-10
                            2xl:h-14
                            rounded-4xl
                            bg-white
                            grid
                            grid-cols-12"
                        >

                            <div className="
                                col-start-1
                                col-end-4
                                w-full
                                h-full
                                flex
                                justify-center
                                items-center
                                text-lime"
                            >

                                <FaTrash className="trash-small-fa"/>

                            </div>

                            <div className="
                                col-span-8
                                w-full
                                h-full
                                flex
                                justify-center
                                items-center
                                text-gray-500
                                md:text-sm
                                1xl:text-lg
                                2xl:text-2xl"
                            >

                                Remove item from Sale

                            </div>

                        </button>
                    
                    </div>

                </div>

            </div>

            { showAddNote && (

                <ModalOne 
                    modalOneClose={handleAddNoteClick} 
                    headerText={'Select a Reason or Type your own...'} 
                    headerBackgroundColor={'bg-lime'}
                >

                    <div 
                        className="
                        col-start-1
                        col-end-10
                        bg-gray-500
                        grid
                        grid-rows-12
                        p-2"
                    >

                        <label
                            onClick={(e) => dispatch(onLabelClick({targetID: e.target.id, innerHTML: e.target.innerHTML}))}
                            onKeyDown={(e) => dispatch(onKeyDown({key: e.key}))} 
                            className="
                            w-full
                            h-full
                            row-start-1
                            row-end-6
                            bg-white
                            text-black
                            md:text-3xl 
                            2xl:text-5xl
                            break-all
                            p-2"
                            tabIndex="0"
                            id="add-note"
                        >
                        </label>
                        
                        <div className="
                            row-start-6
                            row-end-13
                            flex
                            flex-col
                            justify-center
                            items-center"
                        >
                            
                            <FullKeyboard 
                                keybBtnStyle="
                                    flex 
                                    justify-center 
                                    items-center 
                                    md:w-14 
                                    md:h-14 
                                    1xl:w-16 
                                    1xl:h-16 
                                    2xl:w-24 
                                    2xl:h-24 
                                    md:text-4xl 
                                    1xl:text-5xl 
                                    2xl:text-6xl 
                                    focus:outline-none"

                                keybBtnStyle1="
                                    flex 
                                    justify-center 
                                    items-center 
                                    md:w-48 
                                    md:h-14 
                                    1xl:w-48 
                                    1xl:h-16 
                                    2xl:w-64 
                                    2xl:h-24 
                                    md:text-3xl 
                                    1xl:text-4xl 
                                    2xl:text-5xl 
                                    focus:outline-none"
                            /> 

                        </div>

                    </div>

                    <div 
                        className="
                        w-full
                        h-full
                        col-start-10
                        col-end-13
                        bg-gray-800
                        grid
                        grid-rows-12
                        overflow-y-scroll"
                    >

                        <div className="
                            w-full
                            h-full
                            row-start-1
                            row-end-11
                            overflow-y-scroll
                            pt-2
                            pr-5
                            pl-5
                            pb-2"
                        >

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="one"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Regular Customer'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="two"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Broken Item'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="three"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Staff Discount'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="four"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Nice Person Discount'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="five"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Trade Discount'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="six"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Inconvenience'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="seven"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Bulk Purchase'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="eight"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Overseas Customer'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="nine"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'Good Negotiator'}
                                selected={selected}
                                multiple={true}
                            />

                            <ButtonWithRoundedCornersAndText 
                                onClick={handModalOneButtonClick}
                                name="ten"
                                classText={`
                                    md:h-14
                                    2xl:h-20
                                    md:text-xl
                                    1xl:text-2xl
                                    2xl:text-4xl
                                    text-white`}
                                activeBackColor={'bg-lime'}
                                text={'I fancied the Customer'}
                                selected={selected}
                                multiple={true}
                            />

                        </div>

                        <div className="
                            row-span-2
                            flex
                            justify-center
                            items-center
                            p-2"
                        >

                            <ButtonWithRoundedCornersAndText 
                                onClick={handleModalOneSaveClick}
                                classText={`
                                    h-full
                                    md:text-4xl
                                    2xl:text-6xl
                                    text-white`}
                                inactiveBackColor={'bg-white'}
                                inactiveForeColor={'text-black'}  
                                text={'Save'}
                                multiple={false}
                            />

                        </div>

                    </div>

                </ModalOne>

            )}

        </>

    )

}

export default CollapsibleOne