import axios from "axios"
import UserData from "../../data/UserData"

let API_URL = "/dummybackend/api/user/read.php";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
{
    API_URL = `${process.env.REACT_APP_API_SERVER}/dummybackend/api/user/read.php`;
}

console.log(API_URL)

// Get users

const getUsers = async (token) => {

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }

    // const response = await axios.get(API_URL, config)
    const response = await axios.get(API_URL)

    return response.data

    // const response = UserData

    // return response

}

// Select user

const selectUser = async (user) => {

    return user

}

// Login user

const login = async (user, password) => {

    return new Promise(function(resolve, reject) { 

        if(password.length > 0)
        {
            if(password == "1")
            {   
                localStorage.setItem("user", JSON.stringify(user))
                
                resolve(user)
            }
            else
            {
                reject({message: "Incorret password"})
            }
        }
        else
        {
            reject({message: "Please enter password"})
        }
    
    });

    
}

// Remove selected user

const logout = () => localStorage.removeItem("user")

const userService = {
    getUsers,
    selectUser,
    login,
    logout
}

export default userService