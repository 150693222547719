import RoundLevelIndicator from "./RoundLevelIndicator";

const ImageGridItem = ({
    onClick, 
    id,
    image, 
    description,
    wh,
    bgDescriptionColor,
    bgTextColor,
    centered,
    qty,
    showQty
}) => {

    const imageUrl = process.env.REACT_APP_PRODUCT_IMAGE_DIRECTORY;

    return (
        <>
            <button 
                onClick={onClick}
                className={`
                    relative
                    ${wh}
                    grid 
                    grid-rows-12 
                    border-2 
                    border-solid 
                    border-white
                    md:rounded-md
                    2xl:rounded-xl 
                    bg-white`}
                id={id}
            >

                {
                    showQty && (

                        <div className="
                            absolute 
                            top-0 
                            right-0 
                            text-black"
                        >

                            <RoundLevelIndicator 
                                qty={qty}
                                useStockLevelColour={false}
                                colour='bg-lime text-white'
                                showStockFigure={false}
                            />

                        </div>

                    )
                }


                <div className="
                    w-full 
                    h-full 
                    row-span-9
                    flex 
                    justify-center 
                    items-center"
                >
                    
                    <img
                        className="
                            w-full 
                            h-full 
                            md:rounded-tl-md
                            md:rounded-tr-md
                            2xl:rounded-tl-xl
                            2xl:rounded-tr-xl
                            object-scale-down"
                        alt=""
                        src={imageUrl + image}
                    />

                </div>

                <div className={`
                    w-full 
                    h-full 
                    row-span-3 
                    flex 
                    ${centered && 'justify-center'}
                    items-center 
                    md:rounded-bl-md
                    md:rounded-br-md 
                    2xl:rounded-bl-xl 
                    2xl:rounded-br-xl 
                    ${bgDescriptionColor}
                    ${bgTextColor}
                    md:text-xs
                    2xl:text-lg
                    truncate`}
                >
                    
                    {description}

                </div>
                    
            </button>

        </>
    )
}

export default ImageGridItem