import StockWithParentStockColour from "../../js/shared/StockWithParentStockColour"

const RoundLevelIndicatorWithParentStock = ({
    stock, 
	minStockLevel, 
	parentStock, 
	parentMinStockLevel
}) => {

    const stockString = StockWithParentStockColour(stock, minStockLevel, parentStock, parentMinStockLevel)

    return (
        <div className="
            relative"
        >
            <div className={`
                absolute
                top-0
                md:w-16
                md:h-6
                1xl:w-16
                1xl:h-6
                2xl:w-20
                2xl:h-8
                flex
                justify-center
                items-center
                md:rounded-full
                md:text-sm
                1xl:text-sm
                2xl:text-lg
                md:ml-2
                md:pl-4
                1xl:ml-2
                1xl:pl-4
                2xl:ml-4
                2xl:pl-6
                ${stockString.parentStockClass}`}
            >
                
                {parentStock}

            </div>

            <div className={`
                absolute
                top-0
                md:w-8
                md:h-8
                1xl:w-8
                1xl:h-8
                2xl:w-12
                2xl:h-12
                flex
                justify-center
                items-center
                md:rounded-full
                md:text-xs
                1xl:text-sm
                2xl:text-lg
                ${stockString.stockClass}`}
            >
                
                {stock}

            </div>
            
        </div>
    )
}

export default RoundLevelIndicatorWithParentStock