import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showMainPageSideBarInfoPanelOne: false,
    showMainPageSideBarInfoPanelTwo: false,
    showProductMenuModal: false,
    showProductMenuListView: false,
    showCollapsibleOne: false,
    showCollapsibleTwo: false,
    showCollapsibleThree: false
}

export const mainPageSlice = createSlice({
    name: "mainpage",
    initialState,
    reducers: {
        setShowMainPageSideBarInfoPanelOne: (state, action) => {
            state.showMainPageSideBarInfoPanelOne = action.payload
        }, 
        setShowMainPageSideBarInfoPanelTwo: (state, action) => {
            state.showMainPageSideBarInfoPanelTwo = action.payload
        }, 
        setShowProductMenuModal: (state, action) => {
            state.showProductMenuModal = action.payload
        },
        setShowProductMenuListView: (state, action) => {
            state.showProductMenuListView = action.payload
        },
        setShowCollapsibleOne: (state, action) => {
            state.showCollapsibleOne = action.payload
        },
        setShowCollapsibleTwo: (state, action) => {
            state.showCollapsibleTwo = action.payload
        },
        setShowCollapsibleThree: (state, action) => {
            state.showCollapsibleThree = action.payload
        }
    },
    extraReducers: (builder) => {
        
    }
})

export const { 
    setShowMainPageSideBarInfoPanelOne,
    setShowMainPageSideBarInfoPanelTwo,
    setShowProductMenuModal,
    setShowProductMenuModalSideBar,
    setShowProductMenuListView,
    setShowCollapsibleOne,
    setShowCollapsibleTwo,
    setShowCollapsibleThree
} = mainPageSlice.actions

export default mainPageSlice.reducer