import { useSelector } from "react-redux";

const CustomerSearchViewSideBarInfo = () => {

    const { 
        showSidebar
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showCustomerSearchViewSideBarInfo
    } = 
    useSelector(
        state => state.customer
    )

    return (
    
        showSidebar && showCustomerSearchViewSideBarInfo && (
        
            <>
            
                <div className="row-start-1 row-end-2 pt-2 bg-yellow-300">
                    
                    7

                </div>

                
                <div className="row-span-11 bg-blue-300">

                    8

                </div> 

            </>
        ) 
    )
}

export default CustomerSearchViewSideBarInfo