export const productMenuModalSideBarList = [
  {
    name: "Show Stock",
  },
  {
    name: "Show Blank Stock",
  },
  {
    name: "Show Price",
  },
  {
    name: "Show Product Info Panel"
  },
  {
    name: "Autosize Grid",
  }
];