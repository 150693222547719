const CircleButtonOne = ({onClick, cssText, text}) => {

    return (

        <button 
            onClick={onClick}
            className={`
                flex
                justify-center
                items-center
                md:h-19 
                md:w-19
                xl:h-24 
                xl:w-24 
                2xl:h-34 
                2xl:w-34
                rounded-full 
                p-1 
                md:text-lg 
                xl:text-2xl 
                2xl:text-3xl
                ${cssText}`}  
        >
            
            {text}

        </button>
        
    )

}

export default CircleButtonOne