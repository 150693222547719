const handleKeyboardDown = (key, selectedLabel) => {

    var label = document.getElementById(selectedLabel)

    if(label !== null)
    {
        var str = label.innerHTML;

        switch(key)
        {
            case "Alt"      :   break;
            case "CapsLock" :   break;
            case "Control"  :   break;
            case "Enter"    :   break;
            case "Insert"   :   break;    
            case "Shift"    :   break;    
            case "Tab"      :   break;
            case " "        :   str = str + " ";
                                break;
            case "Backspace":   if(str.substring(str.length - 6) === "&nbsp;")
                                {
                                    str = str.slice(0, -6);
                                }
                                else
                                {
                                    str = str.slice(0, -1);
                                }
                                break;
            default: 
                    str += key;
        }

        var html = str.slice();
            
        str = str.replace(/&nbsp;/g, ' ')  
        
        html = html.replace(/\s+/g, '&nbsp;')
        
        label.innerHTML = html

        return str
    }
}

export default handleKeyboardDown