import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showProductMenuModalSidebar: false,
    showProductMenuModalListView: false
}

export const productMenuSlice = createSlice({
    name: "productmenu",
    initialState,
    reducers: {
        setShowProductMenuModalSideBar: (state, action) => {
            state.showProductMenuModalSidebar = action.payload
        },
        setShowProductMenuModalListView: (state, action) => {
            state.showProductMenuModalListView = action.payload
        }
    },
    extraReducers: (builder) => {
        
    }
})

export const {
    setShowProductMenuModalSideBar,
    setShowProductMenuModalListView
} = productMenuSlice.actions

export default productMenuSlice.reducer