import { configureStore } from '@reduxjs/toolkit';
import globalReducer from "../features/global/globalSlice"
import mainPageReducer from "../features/mainpage/mainPageSlice"
import customerReducer from "../features/customers/customerSlice"
import productReducer from "../features/products/productSlice"
import userReducer from "../features/users/userSlice"
import productMenuReducer from "../features/product-menu/productMenuSlice"
import scannedItemReducer from "../features/scanned-item/scannedItemSlice"

export const store = configureStore({
    reducer: {
        global: globalReducer,
        mainpage: mainPageReducer,
        customer: customerReducer,
        product: productReducer,
        user: userReducer,
        productmenu: productMenuReducer,
        scanneditem: scannedItemReducer
    }
});