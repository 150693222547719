import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import SideBar from '../shared/SideBar';
import MainPageSideBar from './MainPageSideBar';
import MainPageSideBarInfoPanelOne from './MainPageSideBarInfoPanelOne';
import MainPageSideBarInfoPanelTwo from './MainPageSideBarInfoPanelTwo';
import { FaArrowLeft } from 'react-icons/fa';
import ProductMenu from '../product-menu/ProductMenu';
import ProductMenuModal from "../product-menu/ProductMenuModal"
import ScannedItems from '../scanned-items/ScannedItems';
import CollapsibleOne from "../shared/CollapsibleOne"
import CollapsibleTwo from "../shared/CollapsibleTwo"
import CollapsibleThree from '../shared/CollapsibleThree';

import { 
    setShowSidebar,
    setShowMainPageView,
    setShowUserPageView,
    setShowKeyboard,
} from "../../features/global/globalSlice";

import { 
    logout,
} from "../../features/users/userSlice";

import { 
    setShowMainPageSideBarInfoPanelOne,
    setShowMainPageSideBarInfoPanelTwo
} from "../../features/mainpage/mainPageSlice";

const MainPageView = () => {

    const { 
        showSidebar,
        showMainPageView
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showMainPageSideBarInfoPanelOne,
        showMainPageSideBarInfoPanelTwo,
        showCollapsibleOne,
        showCollapsibleTwo,
        showCollapsibleThree
    } = 
    useSelector(
        state => state.mainpage
    )

    const dispatch = useDispatch();

    useEffect(() => {

        console.log(window.innerWidth, window.innerHeight);

    }, [])

    return (

        <div 
            onClick={ () => {
                showSidebar && dispatch(setShowSidebar(!showSidebar)) 
                showMainPageSideBarInfoPanelOne && dispatch(setShowMainPageSideBarInfoPanelOne(!showMainPageSideBarInfoPanelOne))
                showMainPageSideBarInfoPanelTwo && dispatch(setShowMainPageSideBarInfoPanelTwo(!showMainPageSideBarInfoPanelTwo))

            } } 
            className={`
                h-screen 
                grid 
                grid-rows-12 
                text-white
                ${
                    showMainPageView 
                    ? "w-screen" 
                    : "hidden"
                }`
            }
        >

            <div className="
                w-full 
                h-full 
                row-start-1 
                row-end-2 
                grid 
                grid-cols-12 
                md:pr-10 
                1xl:pr-12 
                2xl:pr-16 
                bg-red-500"
            >
            
                <div className="col-start-1 col-end-5 flex items-center pl-2 bg-purple-500">

                    <button 
                        onClick={ () => {
                            dispatch(logout())
                            dispatch(setShowKeyboard(false))
                            dispatch(setShowUserPageView(true))
                            dispatch(setShowMainPageView(false))
                        } }
                        className="
                            flex
                            justify-center
                            items-center
                            md:h-14 
                            md:w-14 
                            1xl:h-14 
                            1xl:w-14 
                            2xl:h-20 
                            2xl:w-20
                            rounded-full
                            bg-white"
                    >
                        <FaArrowLeft className="arrow-left-fa text-hot-pink"/>
                    </button>

                </div>

                <div className="col-start-5 col-end-9 bg-pink-500">TOP MIDDLE</div>

                <div className="col-start-9 col-end-13 bg-blue-500">TOP RIGHT</div>
            
            </div>

            {/* relative added for CollapsibleThree */}

            <div className="
                relative 
                w-full 
                h-full 
                row-span-11 
                grid 
                grid-cols-12 
                md:pr-10 
                1xl:pr-12 
                2xl:pr-16
                bg-yellow-500"
            >
            
                <div className="
                    w-full 
                    h-full 
                    col-start-1 
                    col-end-5 
                    grid 
                    grid-rows-12
                    overflow-y-auto
                    bg-gray-400"
                >
                    
                    <ScannedItems />

                </div>
            
                <div className="
                    relative
                    w-full 
                    h-full 
                    col-start-5 
                    col-end-9 
                    grid 
                    grid-rows-12
                    overflow-y-auto
                    bg-cyan-500"
                >

                    <ProductMenu />

                    {
                        showCollapsibleOne && (
                            <CollapsibleOne />
                        )
                    }  

                    {
                        showCollapsibleTwo && (
                            <CollapsibleTwo />
                        )
                    }                 
               

                </div>

                {
                    showCollapsibleThree && (
                        <CollapsibleThree />
                    )
                }  

                <div className="
                    w-full 
                    h-full 
                    col-start-9 
                    col-end-13
                    bg-emerald-500"
                >
                    MAIN RIGHT
                </div>
            
            </div>

            <SideBar>
                <MainPageSideBar />
                <MainPageSideBarInfoPanelOne />
                <MainPageSideBarInfoPanelTwo />
            </SideBar>

            <ProductMenuModal />

        </div>

    )
}

export default MainPageView