const checkOS = () => {

    let isWindows = false;
    let isAndroid = false;
    
    if(navigator.appVersion.includes("Windows"))
    {
        isWindows = true
    }
    
    if(navigator.appVersion.includes("Android"))
    {
        isAndroid = true
    }

    return { isWindows: isWindows, isAndroid: isAndroid }

}

export default checkOS