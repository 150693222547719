import { useDispatch } from "react-redux";
import { onKeyClick } from "../../features/global/globalSlice";

const NumericKeyboard = ({ keybBtnStyle }) => {

    const dispatch = useDispatch()

    const handleKeyboardClick = (e) =>{

        const keyType = e.target.getAttribute('data-keytype')

        dispatch(onKeyClick({
            keyType: keyType, 
            innerHTML: e.currentTarget.innerHTML
        }))

    }

    return (

        <>

            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">1</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">2</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">3</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">4</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">5</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">6</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">7</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">8</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">9</button>
            </div>
            <div className="flex justify-center align-items">
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">-</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="num">0</button>
                <button onClick={handleKeyboardClick} className={keybBtnStyle} data-keytype="delete">&lt;</button>
            </div>
        </>

    )

}

export default NumericKeyboard