import GetRGBComponents from "./GetRGBComponents";

const IdealTextColor = (bgColor) => {

    var nThreshold = 105;
    var components = GetRGBComponents(bgColor);
    var bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

    return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";  

}

export default IdealTextColor