import { useDispatch } from "react-redux";
import largeTimes from '../assets/large-times.svg'
import { FaToolbox } from 'react-icons/fa';
import ButtonWithCircleIconAndText from "./ButtonWithCircleIconAndText";

import { 
    setShowCollapsibleThree
} from "../../features/mainpage/mainPageSlice";

const CollapsibleThree = () => {

    const dispatch = useDispatch();
    
    const closeCollapsibleThree = () => {

        dispatch(setShowCollapsibleThree(false))

    }

    return (

        <div className="
            collapsible-three
            bg-lime
            text-white
            grid
            grid-rows-12"
        >
            
            <div className="
                w-full
                h-full
                row-start-1 
                row-end-2"
            >
                
                <div className="
                    w-full
                    h-full
                    flex
                    justify-between
                    items-center
                    p-4"
                >

                    <p className="
                        md:text-xl
                        1xl:text-2xl
                        2xl:text-3xl"
                    >
                        Add Process
                    </p>

                    <button
                        onClick={closeCollapsibleThree}
                    >
                        <img
                            className="edit-product-times-large-fa text-white"
                            alt="Close"
                            src={largeTimes}
                        />
                    </button>

                </div>
                
            </div>

            <div className="
                w-full
                h-full
                row-span-11
                p-2
                overflow-y-scroll"
            >
            
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />

                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />
                <ButtonWithCircleIconAndText 
                    buttonBackground={'bg-white mb-2'}
                    circleBackgroundColor={'bg-gray-500'} 
                    circleTextColor={'text-white'}
                    icon={<FaToolbox className="toolbox-small-fa"/>}
                    text={'Repair'}
                    textColor={'text-gray-500'}
                />

            </div>

        </div>

    )

}

export default CollapsibleThree