import { useSelector } from "react-redux";

const MainPageSideBarInfoPanelTwo = () => {

    const { 
        showSidebar
    } = 
    useSelector(
        state => state.global
    )

    const { 
        showMainPageSideBarInfoPanelTwo
    } = 
    useSelector(
        state => state.mainpage
    )
  
    return (
    
        showSidebar && showMainPageSideBarInfoPanelTwo && (

        <>

            <div className="row-start-1 row-end-2 pt-2 bg-yellow-300">
                4
            </div>

            <div className="row-span-7 bg-blue-300">
                5
            </div> 

            <div className="row-start-9 row-end-13 bg-purple-300">
                6
            </div>

        </> 

        )
    )
}

export default MainPageSideBarInfoPanelTwo