import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaBars } from 'react-icons/fa';
import largeTimes from '../assets/large-times.svg'
import backButton from '../assets/back-button.svg'
import ProductMenuModalSideBar from './ProductMenuModalSideBar';
import ProductMenuGridColorTableCell from './ProductMenuModalGridColorTableCell';
import ProductMenuGridInfoTableCell from './ProductMenuModalGridInfoTableCell';
import ProductMenuModalListItem from './ProductMenuModalListItem';

import { 
    setShowProductMenuModal
} from "../../features/mainpage/mainPageSlice";

import { 
    setShowProductMenuModalSideBar,
    setShowProductMenuModalListView
} from "../../features/product-menu/productMenuSlice";

const ProductMenuModal = () => {

    const imageUrl = process.env.REACT_APP_PRODUCT_IMAGE_DIRECTORY;

    const { 
        showProductMenuModal,
    } = 
    useSelector(
        state => state.mainpage
    )

    const { 
        showProductMenuModalSidebar,
        showProductMenuModalListView
    } = 
    useSelector(
        state => state.productmenu
    )

    const dispatch = useDispatch();

    const handleProductMenuModalClose = () => {

        dispatch(setShowProductMenuModal(!showProductMenuModal))

        dispatch(setShowProductMenuModalSideBar(false))

    }

    const handleChange = () => {

        dispatch(setShowProductMenuModalListView(!showProductMenuModalListView))

    }

    const handleShowProductMenuModalSideBar = () => {

        dispatch(setShowProductMenuModalSideBar(!showProductMenuModalSidebar))

    }

    return (
        <>

            {showProductMenuModal ? (

                <>

                    <div
                        className="
                            justify-center 
                            items-center 
                            flex 
                            overflow-x-hidden 
                            overflow-y-auto 
                            fixed 
                            inset-0 
                            z-50 
                            outline-none 
                            focus:outline-none"
                    >
                        <div className="
                            relative 
                            h-5/6
                            w-11/12">

                            {/*content*/}

                            <div className="
                                border-0 
                                shadow-lg 
                                h-full
                                w-full 
                                bg-black"
                            >
                                
                                <div    
                                    className="
                                        w-full
                                        h-full
                                        grid 
                                        grid-rows-12 
                                        text-white"
                                >
                                    
                                    <div className="
                                        h-full
                                        w-full 
                                        row-start-1 
                                        row-end-2 
                                        grid 
                                        grid-cols-12
                                        bg-lime"
                                    >
                                    
                                        <div className="
                                            col-start-1 
                                            col-end-5 
                                            flex 
                                            items-center 
                                            pl-2"
                                        >

                                            <div className="
                                                w-full
                                                h-full
                                                flex
                                                items-center"
                                            >
                                            
                                                <div 
                                                    className="
                                                    flex
                                                    items-center"
                                                >
                                                    <button
                                                        onClick={handleProductMenuModalClose}
                                                        className="
                                                        pl-2
                                                        w-full
                                                        h-full"
                                                    >
                                                        <img                                               
                                                            className="modal-one-back-button-fa text-white"
                                                            alt="Close"
                                                            src={backButton}
                                                        />    
                                                    </button>
                                                </div>

                                                <div 
                                                    className="
                                                    w-full
                                                    h-full
                                                    flex
                                                    items-center
                                                    md:text-2xl
                                                    2xl:text-4xl
                                                    pl-5"
                                                >
                                                    Please select option....
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-start-5 col-end-9"></div>

                                        <div className="col-start-9 col-end-13 grid grid-cols-6">

                                            <div className="
                                                w-full
                                                h-full 
                                                col-span-2"
                                            >
                                            </div>
                                            
                                            <div className="
                                                w-full
                                                h-full 
                                                col-span-2
                                                flex
                                                justify-center
                                                items-center"
                                            >

                                                <label className="switch-2">
                                                    <input type="checkbox" onChange={handleChange} checked={showProductMenuModalListView} />
                                                    <span className="slider-base-2 slider-5 round"></span>
                                                </label>

                                            </div>

                                            <div className="
                                                w-full
                                                h-full 
                                                col-start-5 
                                                col-end-6 
                                                flex 
                                                justify-center 
                                                items-center"
                                            >

                                                <button 
                                                    onClick={handleShowProductMenuModalSideBar}
                                                >
                                                    <FaBars className="modal-one-bars-large-fa" />
                                                </button>

                                            </div>

                                            <div className="
                                                w-full
                                                h-full 
                                                col-start-6 
                                                col-end-7
                                                flex 
                                                justify-center 
                                                items-center"
                                            >
                                                <button
                                                    onClick={handleProductMenuModalClose}
                                                >
                                                    <img
                                                        className="modal-one-times-large-fa text-white"
                                                        alt="Close"
                                                        src={largeTimes}
                                                    />
                                                </button>
                                                
                                            </div>

                                        </div>
                                    
                                    </div>

                                    <div className="
                                        w-full 
                                        h-full 
                                        row-start-2 
                                        row-end-13 
                                        grid 
                                        grid-cols-12"
                                    >

                                        <div className="
                                            col-start-1 
                                            col-end-4
                                            bg-dark-grey
                                            p-2
                                            overflow-y-scroll"
                                        >

                                            <div className="
                                                w-full
                                                h-full
                                                grid 
                                                grid-rows-12"
                                            >

                                                <div className="
                                                    w-full
                                                    h-full
                                                    row-start-1 
                                                    row-end-4
                                                    flex
                                                    justify-center
                                                    items-center"
                                                >

                                                    <img
                                                        className="
                                                            md:w-32
                                                            md:h-32
                                                            2xl:w-48
                                                            2xl:h-48
                                                            md:rounded-md
                                                            2xl:rounded-xl
                                                            object-scale-down"
                                                        alt=""
                                                        src={imageUrl + "f2951000-11@2x.png"}
                                                    />

                                                </div>

                                                <div className="
                                                    w-full
                                                    h-full
                                                    row-start-4 
                                                    row-end-6"
                                                >

                                                    <p className="
                                                        w-full
                                                        h-full
                                                        flex
                                                        justify-center
                                                        items-center
                                                        md:text-2xl
                                                        2xl:text-4xl
                                                        opacity-70"
                                                    >
                                                        AK47 Lamp
                                                    </p>   

                                                </div>

                                                <div className="
                                                    w-full
                                                    h-full
                                                    row-span-7"
                                                >

                                                    <p className="
                                                        w-full
                                                        h-full
                                                        md:text-lg
                                                        2xl:text-3xl
                                                        text-center
                                                        opacity-70"
                                                    >
                                                        This is a description of the AK47 lamp along with the wording used on the website. This would be taken from the Product Outline or the Web Description.
                                                    </p> 

                                                </div>

                                            </div>

                                        </div>

                                        <div className="
                                            w-full
                                            h-full
                                            col-start-4 
                                            col-end-13
                                            overflow-y-scroll
                                            flex
                                            justify-center
                                            items-center"
                                        >

                                            {
                                                showProductMenuModalListView ? (

                                                    <div className="
                                                        w-full 
                                                        h-full 
                                                        grid 
                                                        grid-cols-8
                                                        p-4"
                                                    >

                                                        <div className="
                                                            w-full 
                                                            h-full 
                                                            col-start-1 
                                                            col-end-7"
                                                        >

                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'22'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'24'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'26'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'28'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'30'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-blue-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Blue'}
                                                                attributeTwo={'32'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'22'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'24'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'26'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'28'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'30'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-red-700'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Red'}
                                                                attributeTwo={'32'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'22'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'24'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'26'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'28'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'30'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-green-600'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Green'}
                                                                attributeTwo={'32'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'22'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'24'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'26'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'28'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'30'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-gray-500'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Charcoal'}
                                                                attributeTwo={'32'}
                                                                currency={'£'}
                                                            />
                                                             <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'22'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'24'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'26'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'28'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'30'}
                                                                currency={'£'}
                                                            />
                                                            <ProductMenuModalListItem 
                                                                backgroundColor={'bg-black'} 
                                                                color={'text-white'}
                                                                price={12.50}
                                                                stock={12}
                                                                minStockLevel={1}
                                                                parentStock={4}
                                                                parentMinStockLevel={5}
                                                                attributeOne={'Black'}
                                                                attributeTwo={'32'}
                                                                currency={'£'}
                                                            />
                                                            
                                                        </div>

                                                        <div className="w-full h-full col-start-7 col-end-9"></div>
                                                        
                                                    </div>

                                                ) : (

                                                    <div className="
                                                        product-menu-table-container
                                                        overflow-x-auto"
                                                    >
                                                    
                                                        <table className="product-menu-table md:text-md 2xl:text-2xl">
                                                            <thead>
                                                                <tr>
                                                                    <th className="fix bg-black"></th>
                                                                    <th className="bg-black z-[999]">22</th>
                                                                    <th className="bg-black z-[999]">24</th>
                                                                    <th className="bg-black z-[999]">26</th>
                                                                    <th className="bg-black z-[999]">28</th>
                                                                    <th className="bg-black z-[999]">30</th>
                                                                    <th className="bg-black z-[999]">32</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <ProductMenuGridColorTableCell 
                                                                        backgroundColor={'bg-blue-700'} 
                                                                        color={'text-white'}
                                                                        text={'Blue'}
                                                                    />
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                </tr>
                                                                <tr>
                                                                    <ProductMenuGridColorTableCell 
                                                                        backgroundColor={'bg-red-700'} 
                                                                        color={'text-white'}
                                                                        text={'Red'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />     
                                                                </tr>
                                                                <tr>
                                                                    <ProductMenuGridColorTableCell 
                                                                        backgroundColor={'bg-green-600'} 
                                                                        color={'text-white'}
                                                                        text={'Green'}
                                                                    />    
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />        
                                                                </tr>
                                                                <tr>
                                                                    <ProductMenuGridColorTableCell 
                                                                        backgroundColor={'bg-gray-500'} 
                                                                        color={'text-white'}
                                                                        text={'Charcoal'}
                                                                    />     
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />    
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />           
                                                                </tr>
                                                                <tr>
                                                                    <ProductMenuGridColorTableCell 
                                                                        backgroundColor={'bg-black'} 
                                                                        color={'text-white'}
                                                                        text={'Black'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />  
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />   
                                                                    <ProductMenuGridInfoTableCell 
                                                                        price={12.50}
                                                                        stock={12}
                                                                        minStockLevel={1}
                                                                        parentStock={4}
                                                                        parentMinStockLevel={5}
                                                                        currency={'£'}
                                                                    />         
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                ) 

                                            }

                                            <ProductMenuModalSideBar showProductMenuModalSidebar={showProductMenuModalSidebar} />

                                        </div>

                                    </div>
                
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                
                </>

          ) : null}

        </>
    )
}

export default ProductMenuModal