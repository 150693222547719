import StockLevelIndicatorWithParentStock from "../shared/StockLevelIndicatorWithParentStock";

const ProductMenuGridInfoTableCell = ({
	price, 
	stock, 
	minStockLevel, 
	parentStock, 
	parentMinStockLevel,
	currency
}) => {

	return (
		<td className="
			p-2"
		>
			
			<button className="
				w-full
				h-full
				flex
				justify-center
				items-center
				md:rounded-lg
				2xl:rounded-xl
				border-2
				border-white
				bg-gray-300
				text-gray-700"
			>
			
				<div className="w-full h-full grid grid-rows-6">

					<div className="
						row-start-1 
						row-end-3 
						grid 
						grid-cols-12"
					>

						<div className="
							col-start-1 
							col-end-6"
						>
							
							<StockLevelIndicatorWithParentStock 
								stock={stock} 
								minStockLevel={minStockLevel}
								parentStock={parentStock}
								parentMinStockLevel={parentMinStockLevel}
							/>

						</div>

						<div className="col-span-7"></div>

					</div>

					<div className="row-span-2"></div>

					<div className="row-start-5 row-end-7 grid grid-cols-12">

						<div className="col-span-7"></div>
						
						<div className="col-start-8 col-end-13 flex justify-center items-center">

							{currency}{Number(price).toFixed(2)}

						</div>

					</div>

				</div>

			</button>

		</td>
  	)
}

export default ProductMenuGridInfoTableCell