import React from 'react'
import RoundLevelIndicator from './RoundLevelIndicator'

const StockContainer = ({
    stock, 
    minStockLevel, 
    useStockLevelColour, 
    colour, 
    text, 
    onClick
}) => {
    
    return (
        <div
            onClick={onClick} 
            className="
            grid
            grid-cols-12
            md:text-lg
            2xl:text-2xl
            pb-2"
        >
            
            <div className="
                w-full
                h-full
                col-start-1
                col-end-4
                flex
                justify-center
                items-center"                            
            >
                
                <RoundLevelIndicator 
                    stock={stock} 
                    minStockLevel={minStockLevel}
                    useStockLevelColour={useStockLevelColour}
                    colour={colour}
                    showStockFigure={true}
                />

            </div>

            <div className="
                w-full
                h-full
                col-span-8
                md:pl-1
                flex
                items-center"
            >
                
                {text}

            </div>

        </div>
    )
}

export default StockContainer