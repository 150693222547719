import React from 'react'
import Layout3 from '../layouts/Layout3'

const ModalOne = ({
    modalOneClose, 
    headerText, 
    headerBackgroundColor,
    bodyBackgroundColor,
    bodyForegroundColor,
    children
}) => {

    return (

        <>

            <div
                className="
                    justify-center 
                    items-center 
                    flex 
                    overflow-x-hidden 
                    overflow-y-auto 
                    fixed 
                    inset-0 
                    z-50 
                    outline-none 
                    focus:outline-none"
            >
                <div className="
                    relative 
                    h-5/6
                    w-11/12"
                >

                    <div className="
                        border-0 
                        shadow-lg 
                        h-full
                        w-full 
                        bg-black"
                    >

                        <Layout3 
                            onClick={modalOneClose}
                            mainClass={'w-full h-full'}
                            headerText={headerText}
                            headerBackgroundColor={headerBackgroundColor}
                            bodyBackgroundColor={bodyBackgroundColor}
                            bodyForegroundColor={bodyForegroundColor}
                        >

                            {children}

                        </Layout3>

                    </div>

                </div>

            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

        </>
    )
}

export default ModalOne