const StoreStockContainer = ({stock, storeName}) => {

    return (

        <div className="
            grid
            grid-cols-12
            md:text-lg
            2xl:text-2xl"
        >
            
            <div className="col-span-4"></div>

            <div className="
                col-start-4 
                col-end-6 
                flex 
                justify-end 
                pr-2"
            >
                
                {stock}

            </div>

            <div className="
                col-start-6 
                col-end-13"
            >
                
                {storeName}

            </div>

        </div>

    )

}

export default StoreStockContainer