import { useSelector } from "react-redux";
import logo from './assets/logo.png'

const Layout1 = ({ children }) => {

    const { 
        showUserPageView
    } = 
    useSelector(
        state => state.global
    )

    const backgroundImage = process.env.REACT_APP_LOGIN_BACKGROUND

    return (

        <div 
            className={`
                h-screen 
                ${
                    showUserPageView 
                    ? "w-screen" 
                    : "hidden"
                }`
            }
            style={{ 
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `
                    linear-gradient(rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)), 
                    url('${backgroundImage}')` 
            }}
        >

            <div className="h-full w-full grid grid-cols-12 bg-cover bg-center backdrop-blur-sm">

                <div className="h-full w-full col-span-4 grid grid-rows-6 text-white">

                    <div className="row-start-1 row-end-2 flex justify-center items-center">
                        <img className="object-scale-down h-full w-1/2" src={logo} alt="Eskimo EPOS Logo" />
                    </div>
                    <div className="row-span-3"></div>
                    <div className="row-start-5 row-end-7">

                        <div className="h-full w-full grid grid-rows-3">
                            <div className="row-start-1 row-end-3 flex justify-center items-center md:text-8xl xl:text-8xl 2xl:text-9xl">9:33</div>
                            <div className="row-start-3 row-end-4 flex justify-center md:text-3xl xl:text-4xl 2xl:text-5xl">18th January</div>
                        </div>

                    </div> 
                </div>

                <div className="h-full w-full col-span-8 text-white p-10 overflow-y-scroll">

                    { children }

                </div>
            
            </div>

        </div>

    )
}

export default Layout1