const Spinner = () => {
  return (
    <div className="loadingSpinnerContainer">

        <div className="loadingSpinner"></div>

    </div>
  )
}

export default Spinner