import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import RoundLevelIndicator from '../shared/RoundLevelIndicator';

import { 
    setShowProductMenuModal
} from "../../features/mainpage/mainPageSlice";

const ProductMenuListItem = ({description, image, price, stock, minStockLevel, currency}) => {

    const { 
        showProductMenuModal
    } = 
    useSelector(
        state => state.mainpage
    )

    const dispatch = useDispatch();

    const handleClick = () => {

        dispatch(setShowProductMenuModal(!showProductMenuModal))

    }

    const imageUrl = process.env.REACT_APP_PRODUCT_IMAGE_DIRECTORY;

    return (
        <>
            <button 
                onClick={handleClick}
                className="
                    w-full
                    md:h-14
                    1xl:h-14
                    2xl:h-20
                    grid
                    grid-cols-6
                    md:rounded-md
                    2xl:rounded-xl
                    border-2 
                    border-solid 
                    border-white
                    bg-gray-200
                    text-gray-500"
            >

                <div className="w-full h-full col-start-1 col-end-2">

                    <img
                        className="
                            w-full 
                            h-full 
                            md:rounded-md
                            2xl:rounded-xl
                            object-scale-down"
                        alt=""
                        src={imageUrl + image}
                    />

                </div>

                <div className="
                    pl-2
                    pr-2
                    w-full 
                    h-full
                    col-span-3 
                    flex
                    flex-wrap
                    items-center
                    md:text-xs
                    1xl:text-sm
                    2xl:text-lg
                    overflow-y-auto">

                    {description}

                </div>

                <div className="
                    p-2
                    w-full 
                    h-full 
                    flex
                    justify-center
                    items-center
                    truncate 
                    overflow-hidden
                    md:text-xs
                    1xl:text-sm
                    2xl:text-lg"
                >

                    {currency}{Number(price).toFixed(2)}

                </div>

                <div className="
                    w-full 
                    h-full 
                    flex 
                    justify-center 
                    items-center"
                >

                    <RoundLevelIndicator 
                        stock={stock}
                        minStockLevel={minStockLevel}
                        useStockLevelColour={true}
                        showStockFigure={true}
                    />

                </div>

            </button>
        </>
    )
}

export default ProductMenuListItem