import { useSelector } from "react-redux";

const SideBar = ({ children }) => {

  const { 
      showSidebar
  } = 
  useSelector(
      state => state.global
  )

  return (

    <div 
      className={ `w-full h-full top-0 right-0 bg-black opacity-70 text-white fixed 
      ${showSidebar ? "w-1/2 transition-[width] duration-500" : "md:w-10 1xl:w-12 2xl:w-16 transition-[width] duration-500"} `}
    >
      
      <div className={`w-full h-full grid grid-col-1 grid-rows-12 ${!showSidebar && "justify-center"} `}>
        
        { children }

      </div>

    </div>

  )

}

export default SideBar