const ProductMenuGridColorTableCell = ({backgroundColor, color, text}) => {

    return (

        <td className="
            fix 
            flex
            justify-center
            items-center
            p-2
            bg-black"
        >

            <div className={`
                w-full 
                h-full
                flex
                justify-center
                items-center 
                md:rounded-3xl
                2xl:rounded-4xl
                md:text-md 
                2xl:text-2xl
                ${backgroundColor}
                ${color}`}
            >
                
                {text}
                
            </div>

        </td>
    )
}

export default ProductMenuGridColorTableCell