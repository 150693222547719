import IdealTextColor from "../../js/shared/IdealTextColor";
import { useDispatch } from "react-redux";
import { setShowKeyboard } from "../../features/global/globalSlice";
import { selectUser } from "../../features/users/userSlice";

const UserItem = ({ user }) => {

    const dispatch = useDispatch()

    const imageUrl = process.env.REACT_APP_LOGIN_IMAGE_DIRECTORY;

    let imageString = ""
    let initial = "";

    const backColor = user.background_color ? user.background_color : '#FFFFFF';

    if(user.image)
    {
        imageString = 
            <img 
                src={ imageUrl + user.image }
                className="
                    object-scale-down 
                    md:h-24 
                    md:w-24 
                    xl:h-28 
                    xl:w-28 
                    2xl:h-36 
                    2xl:w-36 
                    rounded-full" 
            />
    }
    else
    {
        initial = user.name.charAt(0)
    }

    const handleClick = () => {

        dispatch(selectUser(user))
       
        dispatch(setShowKeyboard(true))

    }

    return (
        <div className="flex flex-col justify-center items-center">
            <button 
                className="
                    md:h-24 
                    md:w-24 
                    xl:h-28 
                    xl:w-28 
                    2xl:h-36 
                    2xl:w-36 
                    rounded-full 
                    pb-2 
                    bg-white 
                    text-black 
                    md:text-5xl 
                    xl:text-6xl 
                    2xl:text-7xl"
                style={{ backgroundColor: backColor, color: IdealTextColor(backColor) }}
                onClick={handleClick}
            >
                {imageString}
                {initial}
            </button>
            <p className="md:text-2xl xl:text-3xl 2xl:text-4xl pt-5">
                {user.name}
            </p>
        </div>
    )
}

export default UserItem