const ButtonWithCircleIconAndText = ({ 
    onClick,
    buttonBackground, 
    circleBackgroundColor, 
    circleTextColor,
    icon,
    text,
    textColor
}) => {
    return (

        <button 
            onClick={onClick}
            className={`
            w-full
            md:h-12
            2xl:h-18
            rounded-5xl
            grid
            grid-cols-12
            ${buttonBackground}`}
        >

            <div className="
                col-start-1
                col-end-4
                w-full
                h-full
                flex
                items-center
                p-1"
            >

                <div className={`
                    md:w-10
                    md:h-10
                    2xl:w-16
                    2xl:h-16
                    flex
                    justify-center
                    items-center
                    rounded-5xl
                    ${circleBackgroundColor}
                    ${circleTextColor}`}
                >

                    {icon}

                </div>      

            </div>

            <div className={`
                col-span-8
                w-full
                h-full
                flex
                justify-start
                items-center
                md:text-lg
                1xl:text-xl
                2xl:text-3xl
                ${textColor}`}
            >

                {text}

            </div>

        </button>
        
    )
}

export default ButtonWithCircleIconAndText