import axios from "axios"

let API_URL = "/dummybackend/api/scanned-items/read.php";
let API_UPDATE_URL = "/dummybackend/api/scanned-items/update.php";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
{
    API_URL = `${process.env.REACT_APP_API_SERVER}/dummybackend/api/scanned-items/read.php`;
    API_UPDATE_URL = `${process.env.REACT_APP_API_SERVER}/dummybackend/api/scanned-items/update.php`;
}

// Get scanned items

const getScannedItems = async (token) => {

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }

    // const response = await axios.get(API_URL, config)
    const response = await axios.get(API_URL)

    return response.data

}

// Update scanned items

const updateScannedItems = async (data, token) => {

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }

    // const response = await axios.post(API_URL, ticketData, config)

    const response = await axios.put(API_UPDATE_URL, data)

    return response.data

}

const scannedItemService = {
    getScannedItems,
    updateScannedItems
}

export default scannedItemService