const handleKeyboardClick = (keyType, innerHTML, selectedLabel) => {

    var label = document.getElementById(selectedLabel)

    if(label !== null)
    {
        let lastValue = label.innerHTML;
        
        var str = innerHTML

        if(keyType !== null)
        {
            if(keyType === "delete")
            {
                if(lastValue.substring(lastValue.length - 6) === "&nbsp;")
                {
                    str = lastValue.slice(0, -6);
                }
                else
                {
                    str = lastValue.slice(0, -1);
                }
            }
            else if(keyType === "space")
            {
                str = lastValue + " "
            }
            else
            {
                str = lastValue.concat(str)
            } 

            if(typeof(label) !== 'undefined')
            {
                var html = str.slice();
                
                str = str.replace(/&nbsp;/g, ' ')     
                
                html = html.replace(/\s+/g, '&nbsp;')
                
                label.innerHTML = html

                return str
            }
        }
    }
}

export default handleKeyboardClick