import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState = {
    showProductSearchViewSideBarInfo: false,
    productMenu: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

// Get users

export const getProductMenu = createAsyncThunk("products/getProductMenu", async (_, thunkAPI) => {

    try {

        return await productService.getProductMenu()

    } catch (error) {

        const message = (
            error.response && 
            error.response.data && 
            error.response.data.message
        ) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }

})

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setShowProductSearchViewSideBarInfo: (state, action) => {
            state.showProductSearchViewSideBarInfo = action.payload
        },
        reset: (state) => {
            state.isLoading =  false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProductMenu.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getProductMenu.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.productMenu = action.payload
        })
        .addCase(getProductMenu.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const { 
    setShowProductSearchViewSideBarInfo,
    reset
} = productSlice.actions

export default productSlice.reducer